import AkinonButton from '@components/AkinonButton';
import { openNotification } from '@components/AkinonNotification';
import Modal from '@components/Modal/Modal';
import Show from '@components/Show';
import { defaultDateTimeFormat } from '@constants/index';
import { Space, Tooltip, Typography } from 'antd';
import { isEmpty } from 'lodash';
import truncate from 'lodash/truncate';
import moment from 'moment';

const { Text } = Typography;
export const StaticFilterKey = {
  ID: 'id__exact',
  ERROR: 'error__icontains',
  IS_RESOLVED: 'is_resolved__exact',
  RESOLVED_AT_START: 'resolved_at__gte',
  RESOLVED_AT_END: 'resolved_at__lte',
  CONTENT_TYPE: 'content_type__exact',
  OBJECT_ID: 'object_id__exact',
  CREATED_AT_START: 'created_at__gte',
  CREATED_AT_END: 'created_at__lte',
  UPDATED_AT_START: 'updated_at__gte',
  UPDATED_AT_END: 'updated_at__lte',
};
export const DataIndex = {
  OBJECT_ID: 'object_id',
  ERROR: 'error',
  IS_RESOLVED: 'is_resolved',
  CREATED_AT: 'created_at',
  CONTENT_TYPE: ['content_type', 'model'],
};

export const getColumns = ({ t, resolveIntegrationError, isMobile }) => {
  return [
    {
      dataIndex: DataIndex.OBJECT_ID,
      title: t('object_id'),
      render(objectId) {
        return <Tooltip title={objectId}>{truncate(objectId)}</Tooltip>;
      },
    },
    {
      dataIndex: DataIndex.CONTENT_TYPE,
      title: t('content_type'),
    },
    {
      dataIndex: DataIndex.ERROR,
      title: t('error'),
      render(error) {
        if (!error) return null;

        const httpErrorSize = 3;
        const errorStatus = error.substring(0, httpErrorSize);
        const matchedError = error.match(/\smessage=({.*}),\s/);
        const errorMessage = Array.isArray(matchedError)
          ? matchedError?.[1]?.replace(/'/g, '"')
          : '';
        const errorUrlMatch = error.match(/url=(.*)/);
        const errorUrl = Array.isArray(errorUrlMatch) ? errorUrlMatch[1] : '';

        return (
          <Tooltip
            title={
              <Space direction="vertical">
                <Text style={{ color: 'white' }} strong>
                  {!isEmpty(matchedError) ? `Error Code: ${errorStatus}` : 'Error'}
                </Text>
                <AkinonButton
                  type="primary"
                  size="small"
                  onClick={() =>
                    openNotification({
                      position: 'bottom-right',
                      message: !isEmpty(matchedError)
                        ? `ErrorCode: ${errorStatus} ${errorUrl}`
                        : 'Error',
                      description: <code>{!isEmpty(matchedError) ? errorMessage : error}</code>,
                      type: 'info',
                    })
                  }
                >
                  {t('show_more')}
                </AkinonButton>
              </Space>
            }
          >
            {truncate(error)}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: DataIndex.IS_RESOLVED,
      title: t('is_resolved'),
      render(isResolved) {
        return isResolved ? (
          <Text strong className="green">
            {t('yes').toUpperCase()}
          </Text>
        ) : (
          <Text strong className="red">
            {t('no').toUpperCase()}
          </Text>
        );
      },
    },
    {
      dataIndex: DataIndex.CREATED_AT,
      title: t('created_at'),
      render(createdAt) {
        return moment(createdAt).format(defaultDateTimeFormat);
      },
    },
    {
      title: t('resolve'),
      fixed: isMobile ? false : 'right',
      className: 'integration-errors__resolve_column',
      render(_, record) {
        const requestConfig = {
          successMessage: t('transaction_success'),
          successDescription: t('integration_errors_message.success'),
          errorMessage: t('transaction_failed'),
          errorDescription: t('integration_errors_message.error'),
        };
        const onClick = () => {
          Modal.confirm({
            title: t('confirm_resolve_integration_error'),
            content: t('confirm_resolve_integration_error_description'),
            onOk() {
              resolveIntegrationError({ integrationErrorId: record.id, requestConfig });
            },
            t,
          });
        };

        return (
          <Show when={!record.is_resolved}>
            <AkinonButton type="primary" onClick={onClick}>
              {t('resolve')}
            </AkinonButton>
          </Show>
        );
      },
    },
  ];
};
