import { getFormRuleMap } from '@common/form';
import { getPathFragments } from '@common/routing';
import AkinonButton from '@components/AkinonButton';
import { DraggerInputWithLabel } from '@components/AkinonDragger';
import useFiltersSlice from '@components/AkinonFilter/hooks/store/useFiltersSlice';
import AkinonForm from '@components/AkinonForm';
// import { openNotification } from '@components/akinonNotification';
import Box from '@components/utility/box/index';
import { FormField } from '@pages/ProductsAndCategories/BulkActions/common';
// import { useDownloadReconciliationRulesTemplateMutation } from '@services/api/hooks/useDownloadReconciliationRulesTemplateMutation';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const BulkSearchProducts = ({ loading }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const formRuleMap = getFormRuleMap({ t });
  const { pathname, search } = useLocation();
  const { mainPath } = getPathFragments(pathname);
  const filterKey = `${mainPath}${search}`;
  const { pageFilters, setFilters } = useFiltersSlice();
  const [form] = Form.useForm();
  // const { mutate: downloadTemplate, isMutating: isDownloadingTemplate } =
  //   useDownloadReconciliationRulesTemplateMutation();

  const handleFileUpload = () => {
    form.validateFields([FormField.FILE]).then(({ file }) => {
      setFilters({
        pagePath: filterKey,
        filters: { ...pageFilters, filter_file: file.fileList?.[0] },
      });
    });
  };

  // const onDownloadTemplate = () => {
  //   downloadTemplate(
  //     { type: 'category' },
  //     {
  //       onSuccess: () => {
  //         openNotification({
  //           message: t('file.preparing'),
  //           description: t('file.download.modal.description'),
  //         });
  //       },
  //     }
  //   );
  // };

  return (
    <>
      <div className="bulk-actions">
        <Box when className="box-primary form-box bulk" title={t('search_bulk_product')}>
          <AkinonForm className="ant-form" form={form} layout="vertical">
            <Form.Item
              name="file"
              rules={[formRuleMap.file()]}
              label={t('bulk_actions_upload_filters')}
            >
              <DraggerInputWithLabel
                accept=".csv,.xlsx,.xls"
                singleAction
                maxCount={1}
                multiple={false}
                beforeUpload={() => false}
              />
            </Form.Item>

            {/* <div className="download-template">
              <span>{t('bulk.actions.download')}</span>
              <AkinonButton
                type="default"
                icon={<i className="icon icon-download" />}
                size="small"
                loading={isDownloadingTemplate}
                onClick={onDownloadTemplate}
              >
                XLSX
              </AkinonButton>
            </div> */}

            <div className="actions">
              <AkinonButton
                loading={loading}
                style={{
                  marginTop: '24px',
                  fontWeight: '600',
                  fontSize: '14px',
                }}
                type="primary"
                onClick={handleFileUpload}
              >
                {t('filter')}
              </AkinonButton>
            </div>
          </AkinonForm>
        </Box>
      </div>
    </>
  );
};

export default BulkSearchProducts;
