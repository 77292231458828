import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonSelect from '@components/AkinonSelect';
import Drawer from '@components/Drawer';
import DynamicFormElement from '@components/DynamicFormElement';
import { WidgetType } from '@constants/commontypes';
import { defaultTimeFormat } from '@constants/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Typography } from 'antd';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getRulesSchema, refineConditions, RuleType, SchemaType } from './common';

const { Text } = Typography;

const getFormItemWidgetProps = (field) => {
  const { type, resource } = field;
  if (type === SchemaType.BOOLEAN) {
    return {
      widget: WidgetType.SWITCH,
      checkedChildren: 'Yes',
      unCheckedChildren: 'No',
      initialValue: false,
    };
  } else if (type === SchemaType.CHOICE) {
    return {
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      options: createSelectOptions(field.choices, {
        labelKey: 'display_name',
        valueKey: 'value',
      }),
    };
  } else if (type === SchemaType.TIME) {
    return { widget: WidgetType.TIME, format: defaultTimeFormat };
  } else if (type === SchemaType.LIST) {
    return {
      widget: WidgetType.ASYNC_SELECT,
      showSearch: true,
      labelInValue: true,
      queryProps: {
        remoteKey: resource?.split?.('/api/v1/')?.at?.(1),
        remoteUrl: resource?.split?.('/api/v1/')?.at?.(1),
        params: {
          limit: 20,
          ...field.params,
        },
        searchKey: 'name__icontains',
        queryOptions: field.queryOptions,
      },
      mode: 'multiple',
      placeholder: field.label,
    };
  } else if (type === SchemaType.MULTI_CHOICE) {
    return {
      widget: WidgetType.CHECKBOX_GROUP,
      options: createSelectOptions(field.choices, {
        labelKey: 'display_name',
        valueKey: 'value',
      }).map((option) => ({
        ...option,
        label: <Text className="text-white">{option.label} </Text>,
      })),
    };
  } else if (type === SchemaType.NUMBER) {
    return { widget: WidgetType.NUMBER };
  } else if (type === SchemaType.STRING) {
    return { widget: WidgetType.INPUT };
  }
};

const NewConditionalRuleFormModal = ({
  onCancel,
  ruleDetail,
  rules,
  handleAppend,
  handleUpdate,
}) => {
  const { t } = useTranslation('Settings');
  const schema = getRulesSchema({ rules: omit(rules, RuleType.LOGICAL), t }).superRefine(
    refineConditions({ t })
  );

  const { handleSubmit, control, reset, watch } = useForm({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {},
  });

  const slug = watch('slug');
  const formValues = useWatch({ control });
  const isVisible = !isNil(ruleDetail);

  useEffect(() => {
    if (!isEmpty(ruleDetail)) {
      reset(ruleDetail);
    }
  }, [ruleDetail]);

  const conditionalRules = get(rules, RuleType.CONDITIONAL);

  const selectedSlug = find(conditionalRules, { slug });
  const fields = omit(get(selectedSlug, 'params', {}), 'slug');

  const onReset = () => {
    reset({
      ...Object.keys(fields).reduce((acc, key) => {
        acc[key] = null;
        return acc;
      }, {}),
      slug: null,
    });
    onCancel?.();
  };

  return (
    <Drawer
      destroyOnClose
      open={isVisible}
      title={
        <Text className="text-white">
          {t(
            !isEmpty(ruleDetail) && !isNil(ruleDetail.index)
              ? 'update_conditional_rule'
              : 'add_new_conditional_rule'
          )}{' '}
        </Text>
      }
      footer={null}
      width={'40%'}
      onClose={onReset}
    >
      <Form layout="vertical" className="box-primary form-box w-full">
        <AkinonSelect
          formItemProps={{
            control,
            name: `slug`,
            label: <Text className="text-white"> {t('rule')} </Text>,
            required: true,
            shouldUnregister: true,
          }}
          showSearch
          optionFilterProp="label"
          options={createSelectOptions(conditionalRules, {
            labelKey: 'name',
            valueKey: 'slug',
          })}
          placeholder="Select Rule"
        />
        {map(fields, (field, key) => {
          const queries = get(field, 'queries', []);
          const queryFields = map(queries, (query, index) => {
            const { query_param, label, resource } = query;
            const prevQuery = index === 0 ? null : queries?.at(index - 1);
            const prevQueryValue = map(
              get(formValues, `${slug}_${prevQuery?.query_param}`),
              'value'
            ).join(',');
            const params = {
              sort: 'name',
            };
            if (prevQuery && !isEmpty(prevQueryValue)) {
              params[`${prevQuery.query_param}__in`] = prevQueryValue;
            }
            const formWidgetProps = getFormItemWidgetProps({
              type: SchemaType.LIST,
              resource,
              label,
              queryOptions: {
                enabled: !prevQuery || !isEmpty(prevQueryValue),
              },
              params,
            });
            return (
              <DynamicFormElement
                key={query_param}
                formItemProps={{
                  control,
                  name: `${slug}_${query_param}`,
                  label: <Text className="text-white">{label}</Text>,
                }}
                {...formWidgetProps}
              />
            );
          });
          const params = {
            sort: 'name',
          };
          if (!isEmpty(queries)) {
            queries.forEach((query) => {
              const { query_param } = query;
              const prevQuery = get(formValues, `${slug}_${query_param}`);
              if (!isEmpty(prevQuery)) {
                params[`${query_param}__in`] = map(prevQuery, 'value').join(',');
              }
            });
          }
          const formWidgetProps = getFormItemWidgetProps({ ...field, params });
          return (
            <>
              {queryFields}
              <DynamicFormElement
                key={key}
                formItemProps={{
                  control,
                  name: key,
                  label: <Text className="text-white">{field.label}</Text>,
                  required: field.required,
                }}
                {...formWidgetProps}
              />
            </>
          );
        })}
        <AkinonButton
          type="primary"
          onClick={handleSubmit((values) => {
            if (!isEmpty(ruleDetail) && !isNil(ruleDetail.index)) {
              handleUpdate({
                index: ruleDetail.index,
                data: {
                  ...omit(ruleDetail, ['_id', 'index']),
                  ...values,
                  title: get(selectedSlug, 'name'),
                },
              });
            } else {
              handleAppend({
                data: {
                  ...values,
                  parentId: ruleDetail.parentId,
                  title: get(selectedSlug, 'name'),
                  canHaveChildren: false,
                },
              });
            }
            onReset();
          })}
        >
          {t(!isEmpty(ruleDetail) && !isNil(ruleDetail.index) ? 'update' : 'add').toUpperCase()}
        </AkinonButton>
      </Form>
    </Drawer>
  );
};

export default NewConditionalRuleFormModal;
