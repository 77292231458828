import './styles.scss';

import { DragOutlined, EyeOutlined } from '@ant-design/icons';
import { getCroppedImageBlob } from '@common/image';
import ActionIcon from '@components/ActionIcon/ActionIcon';
import AkinonButton from '@components/AkinonButton';
import Show from '@components/Show';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useCrop } from '@hooks/useCrop';
import { useStringCaseConverter } from '@hooks/useStringCaseConverter';
import {
  IconExclamationCircle,
  IconPhotoEdit,
  IconRotate2,
  IconRotateClockwise2,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { getBase64 } from '@utils/index';
import { Image, Modal, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import omit from 'lodash/omit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';

const { Title, Text } = Typography;

function SortableImageItem({ onUpdate, file, onRemoveFile, disabled, error }) {
  const { t } = useTranslation();
  const { toUpperCase } = useStringCaseConverter();
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const {
    imageRef,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    rotation,
    setRotation,
    scale,
    resetCrop,
    isCropValid,
  } = useCrop();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: file.uid,
  });

  const src = file?.url ?? file.originFileObj?.thumbUrl;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'pointer',
  };

  const onRemoveHandler = (fileId) => {
    if (disabled) return;
    onRemoveFile?.({ fileId });
  };

  const onRotateClockwise = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  const onRotateCounterClockwise = () => {
    setRotation((prevRotation) => (prevRotation - 90) % 360);
  };

  const onEditImageClick = () => {
    if (disabled) return;
    setIsImageModalVisible(true);
  };

  const closeModal = () => {
    setIsImageModalVisible(false);
    resetCrop();
  };

  const onSaveChanges = async () => {
    const croppedImageBlob = await getCroppedImageBlob({
      rotation,
      scale,
      image: imageRef.current,
      crop: completedCrop,
      type: file.type,
    });
    const base64Image = await getBase64(croppedImageBlob);
    const croppedImageFile = new File([croppedImageBlob], 'prefix'.concat(file.name), {
      type: file.type,
    });
    croppedImageFile.thumbUrl = base64Image;
    onUpdate((fileList) => {
      return fileList.map((prevFile) =>
        prevFile.uid === file.uid
          ? {
              ...prevFile,
              src: base64Image,
              originFileObj: croppedImageFile,
            }
          : prevFile
      );
    });

    closeModal();
  };

  return (
    <div>
      <article ref={setNodeRef} key={src} className="sortable-image-item" style={style}>
        <Image
          {...attributes}
          {...omit(listeners, 'onPointerDown')}
          className="sortable-image-item__image"
          src={src}
          alt="product"
          preview={{
            mask: (
              <div
                style={{
                  position: 'relavite',
                }}
              >
                <EyeOutlined /> {t('preview')}
                <DragOutlined
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onPointerDown={listeners.onPointerDown}
                  style={{
                    position: 'absolute',
                    cursor: 'move',
                    top: -5,
                    right: -5,
                  }}
                />
              </div>
            ),
          }}
        />
        <div className={clsx({ 'sortable-image-item__actions': true, 'is-disabled': disabled })}>
          <ActionIcon Icon={IconTrash} onClick={() => onRemoveHandler(file.uid)} />
          <ActionIcon Icon={IconPhotoEdit} onClick={onEditImageClick} />
          {error && (
            <Tooltip title={error}>
              <IconExclamationCircle className="text-color-error" />
            </Tooltip>
          )}
        </div>
      </article>
      {src && (
        <Modal
          className="sortable-image-item-edit-modal"
          closeIcon={<ActionIcon Icon={IconX} onClick={closeModal} />}
          open={isImageModalVisible}
          footer={null}
          destroyOnClose
        >
          <Title className="sortable-image-item-edit-modal__title" level={4}>
            {t('edit_image')}
          </Title>
          <div className="sortable-image-item-edit-modal__options">
            <ActionIcon onClick={onRotateCounterClockwise} Icon={IconRotate2} />
            <ActionIcon onClick={onRotateClockwise} Icon={IconRotateClockwise2} />
          </div>
          {src && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(crop) => setCompletedCrop(crop)}
              style={{ width: '100%' }}
            >
              <img
                crossOrigin="anonymous"
                ref={imageRef}
                src={src}
                alt="product"
                style={{
                  border: '1px solid #717a8e',
                  width: '100%',
                  transform: `scale(${scale}) rotate(${rotation}deg)`,
                }}
              />
            </ReactCrop>
          )}
          <Show when={!isCropValid}>
            <Text className="sortable-image-item-edit-modal__crop-warning">
              {t('crop_size_constraint')}
            </Text>
          </Show>
          <AkinonButton disabled={!isCropValid && rotation === 0} onClick={onSaveChanges}>
            {toUpperCase(t('save_changes'))}
          </AkinonButton>
        </Modal>
      )}
    </div>
  );
}
export default SortableImageItem;
