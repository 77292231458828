import '../style.scss';

import AkinonSpin from '@components/AkinonSpin';
import AkinonSteps, { AkinonStep } from '@components/AkinonSteps';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { useDataSourceDetailQuery } from '@services/api/hooks/useDataSourceDetailQuery';
import { IconCategory, IconTag } from '@tabler/icons-react';
import { useCurrentStep } from '@utils/hooks/useCurrentStep';
import { useUserRole } from '@utils/hooks/useUserRole';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CategoryBasedCommissionForm from './CategoryBasedCommissionForm';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import ProductBasedCommission from './ProductBasedCommission';

const StepKey = {
  CATEGORY_BASED_COMMISSION: 'categoryBasedCommission',
  PRODUCT_BASED_COMMISSION: 'productBasedCommission',
};

const getStepList = ({ t }) => [
  {
    title: t('product_based_commission'),
    icon: <IconTag size={20} style={{ transform: 'rotate(90deg)' }} />,
    key: StepKey.PRODUCT_BASED_COMMISSION,
    Component: ProductBasedCommission,
  },
  {
    title: t('category_based_commission'),
    icon: <IconCategory size={20} />,
    key: StepKey.CATEGORY_BASED_COMMISSION,
    Component: CategoryBasedCommissionForm,
  },
];

function Commissions() {
  const { t } = useTranslation('Finance');
  const { id: dataSourceId } = useParams();
  const { dataSourceDetail, isDataSourceDetailLoading } = useDataSourceDetailQuery({
    dataSourceId,
    queryOptions: {
      enabled: Boolean(dataSourceId),
    },
  });

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const { currentStep, setCurrentStep } = useCurrentStep({
    stepCount: 2,
    fallbackStep: 0,
  });

  const stepList = getStepList({ t });
  const { Component: StepComponent = () => null } = stepList[currentStep] ?? {};

  useBreadcrumbs();

  return (
    <section className="seller-management-detail">
      <Show when={isSuperUser}>
        <AkinonSpin spinning={isDataSourceDetailLoading} wrapperClassName="max-w-max min-w-72">
          <Box className="seller-management-detail__header">
            <div className="seller-management-detail__header-info">
              <div>
                {t('seller')}: {dataSourceDetail?.name}
              </div>
            </div>
          </Box>
        </AkinonSpin>
      </Show>
      <AkinonSteps current={currentStep} onChange={setCurrentStep}>
        {stepList.map(({ key, title, icon }) => (
          <AkinonStep key={key} title={title} icon={icon} />
        ))}
      </AkinonSteps>

      <StepComponent />
    </section>
  );
}

export default Commissions;
