import './index.scss';

import { PluginRenderer } from '@akinon/app-shell';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createSelectOptions } from '@common/index';
import AkinonCheckbox from '@components/AkinonCheckbox';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import { openNotification } from '@components/AkinonNotification';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import DynamicFormElement from '@components/DynamicFormElement';
import { QuillEditor } from '@components/QuillEditor';
import Show from '@components/Show';
import TitleWithHint from '@components/TitleWithHint';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { OfferApprovalStatus } from '@constants/commontypes';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@hooks/useAppNavigate';
import useRequestTimeoutNotificationOnce from '@hooks/useRequestTimeoutNotificationOnce';
import { useCreateProductMutation } from '@services/api/hooks/useCreateProductMutation';
import { useDynamicSettingsQuery } from '@services/api/hooks/useDynamicSettingsQuery';
import { useApprovePreOfferMutation } from '@services/hooks/preOffers/useApprovePreOfferMutation';
import { usePatchPreOffersMutation } from '@services/hooks/preOffers/usePatchPreOffersMutation';
import { useSubmitPreOfferMutation } from '@services/hooks/preOffers/useSubmitPreOfferMutation';
import { useOptionsProductPricesQuery } from '@services/hooks/products/useOptionsProductPricesQuery';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { resetPreOfferSelector } from '@zustand-store/selectors/preOffer';
import { Col, Space, Tooltip } from 'antd';
import clsx from 'clsx';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean, useUnmount } from 'react-use';

import { DataType, DataTypeWidgetMap, FormKey, getOfferDetailFormResolver } from './common';
import CreateProductModal from './components/CreateProductModal';
import ProductImageUpload from './components/ProductImageUpload';
import RejectModal from './components/RejectModal';
import RelatedProductsTable from './components/RelatedProductsTable';
import ResolveRejectionModal from './components/ResolveRejectionModal';
import SubmitForm from './components/SubmitForm';
import WithdrawModal from './components/WithdrawModal';
import { useQueries } from './hooks/api/useQueries';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useOnSubmit } from './hooks/useOnSubmit';
import { usePageMeta } from './hooks/usePageMeta';

const OfferDetail = () => {
  const { t } = useTranslation('ProductsAndCategories');
  const { offerId } = useParams();
  const navigate = useAppNavigate();

  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData();

  const { productPricesOptions, isProductPricesOptionsLoading } = useOptionsProductPricesQuery();
  const currencyOptions = createSelectOptions(
    productPricesOptions?.actions?.POST.currency_type.choices,
    {
      valueKey: 'value',
      labelKey: 'display_name',
    }
  );

  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isCreateProductModalVisible, setIsCreateProductModalVisible] = useState(false);
  const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useBoolean(false);
  const [isResolveRejectionModalVisible, setIsResolveRejectionModalVisible] = useBoolean(false);

  const userRole = useUserRole();
  const { patchPreOffers, isPatchPreOffersMutating } = usePatchPreOffersMutation();
  const { submitPreOffer, isSubmitPreOfferMutating } = useSubmitPreOfferMutation();
  const { approvePreOffer, isApprovePreOfferMutating } = useApprovePreOfferMutation();
  const resetPreOffer = useStore(resetPreOfferSelector);
  const {
    preOffer,
    isPreOfferLoading,
    preOfferError,
    dataSourceDetail,
    isFetchingDataSourceDetail,
    dataSources,
    categories,
    priceLists,
    stockLists,
    buyboxPrice,
    buyboxPriceError,
    isBuyBoxPriceLoading,
    lowestPrice,
    lowestPriceError,
    isLowestPriceLoading,
    dynamicFields,
    productAttributesError,
    attributeConfigsError,
  } = useQueries({ t });

  const [variantDynamicFields, variantListAttributeFields] = dynamicFields;

  const { createProduct, isCreatingProduct } = useCreateProductMutation({
    mutationOptions: {
      onSuccess() {
        openNotification({
          message: t('operation_successful'),
          description: t('product_created_successfully'),
        });
      },
      onError() {
        openNotification({
          message: t('an_error_occurred'),
          description: t('product_created_error'),
          type: 'error',
        });
      },
      onSettled() {
        setIsCreateProductModalVisible(false);
      },
    },
  });
  const isPendingApproval = preOffer?.approval_status === OfferApprovalStatus.PENDING_APPROVAL;
  const isRejected = preOffer?.approval_status === OfferApprovalStatus.REJECTED;
  const isAlreadyResolved = isEmpty(preOffer?.rejection) || preOffer?.rejection.is_resolved;
  const isSeller = userRole === UserRole.SELLER;
  const canWithdrawOffer = isPendingApproval && isSeller;
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const canSuperUserEdit =
    isSuperUser &&
    (preOffer?.approval_status === OfferApprovalStatus.PENDING_APPROVAL ||
      preOffer?.approval_status === OfferApprovalStatus.REJECTED);
  const canSellerEdit =
    isSeller && preOffer?.approval_status === OfferApprovalStatus.WAITING_FOR_SELLERS_APPROVAL;
  const canEditOffer = isSuperUser ? canSuperUserEdit : canSellerEdit;

  useUnmount(() => {
    resetPreOffer();
  });

  const showCreateProductModal = () => {
    setIsCreateProductModalVisible(true);
  };

  useBreadcrumbs({
    offerStatus: preOffer?.approval_status,
  });
  usePageMeta({
    showCreateProductModal,
    canWithdrawOffer,
    setIsWithdrawModalVisible,
    isAlreadyResolved,
    isRejected,
    setIsResolveRejectionModalVisible,
  });

  const initialFormValues = useMemo(() => {
    return {
      sku: preOffer?.sku,
      category: preOffer?.category,
      name: preOffer?.name,
      base_code: preOffer?.base_code,
      restrict_for_others: preOffer?.restrict_for_others,
      stock: first(preOffer?.stocks)?.stock,
      retail_price: first(preOffer?.prices)?.retail_price,
      price: first(preOffer?.prices)?.price,
      reference_price: buyboxPrice,
      lowest_price: lowestPrice,
      currency_type: first(preOffer?.prices)?.currency_type,
      tax_rate: first(preOffer?.prices)?.tax_rate,
      SELLER_BARCODE: preOffer?.attributes?.SELLER_BARCODE,
      attributes: {
        ...preOffer?.attributes,
      },
    };
  }, [preOffer, buyboxPrice, lowestPrice]);

  const form = useForm({
    mode: 'onChange',
    resolver: getOfferDetailFormResolver({ t, dynamicFields, canEditOffer, isSuperUser }),
    values: initialFormValues,
  });
  const { control, handleSubmit, watch } = form;

  const onSubmit = useOnSubmit({
    watch,
    preOffer,
    priceLists,
    stockLists,
    dataSources,
    patchPreOffers,
    submitPreOffer,
    approvePreOffer,
    setIsRejectModalVisible,
    isSuperUser,
    canEditOffer,
  });

  const onHandleSubmit = useCallback(
    ({ isRejectAction, isSubmitAction }) =>
      handleSubmit((formValues) => {
        onSubmit({ formValues, isRejectAction, isSubmitAction });
      })(),
    [handleSubmit, onSubmit]
  );

  const { dynamicSettings: maxImageDimensionSettings } = useDynamicSettingsQuery({
    params: {
      key: 'MAX_IMAGE_DIMENSIONS',
      is_active: true,
    },
  });
  const { dynamicSettings: minImageDimensionSettings } = useDynamicSettingsQuery({
    params: {
      key: 'MIN_IMAGE_DIMENSIONS',
      is_active: true,
    },
  });

  const maxImageDimensions = maxImageDimensionSettings?.find(
    (setting) => setting.key === 'MAX_IMAGE_DIMENSIONS'
  )?.value;
  const minImageDimensions = minImageDimensionSettings?.find(
    (setting) => setting.key === 'MIN_IMAGE_DIMENSIONS'
  )?.value;

  const hasError =
    preOfferError ??
    buyboxPriceError ??
    lowestPriceError ??
    productAttributesError ??
    attributeConfigsError;
  const getErrorMessage = (error) => get(error, 'detail') ?? t('this_field_could_not_be_loaded');
  const getFormItemErrorMessage = ({ error }) => {
    if (!error) return {};

    return {
      status: 'error',
      prefix: (
        <Tooltip title={getErrorMessage(error)} placement="topLeft">
          <ExclamationCircleOutlined />
        </Tooltip>
      ),
    };
  };

  const onResolveRejectionClose = ({ isRejectionResolved }) => {
    if (isRejectionResolved) {
      navigate(RouteUrls.productsAndCategories.offerList.rejected);
    }

    setIsResolveRejectionModalVisible(false);
  };

  useRequestTimeoutNotificationOnce({ error: hasError });

  return (
    <section className="single-product-form">
      <Box
        className="box-primary form-box basic-info-form"
        title={t('basic_product_info_title')}
        subtitle={t('basic_product_info_subtitle')}
      >
        <AkinonSpin
          spinning={isFetchingDataSourceDetail}
          wrapperClassName={clsx('', {
            'restrict-created-products_spin': dataSourceDetail?.conf?.restrict_created_products,
          })}
        >
          <AkinonForm
            className="akn-form akn-form-v2"
            name="basicProductInfoForm"
            layout="vertical"
            colon
            {...verticalFormItemLayout}
          >
            <AkinonInput
              formItemProps={{
                ...verticalFormItemLayout,
                label: t('product_name'),
                required: true,
                name: FormKey.NAME,
                control,
                disabled: !isSuperUser,
              }}
            />

            <AkinonInput
              formItemProps={{
                ...verticalFormItemLayout,
                name: FormKey.BASE_CODE,
                control,
                label: t('basecode'),
                tooltip: {
                  title: t('base_code_info'),
                  style: { strokeColor: 'white' },
                },
                required: true,
                disabled: !isSuperUser,
              }}
            />
            <AkinonTreeSelect
              formItemProps={{
                ...verticalFormItemLayout,
                name: FormKey.CATEGORY,
                control,
                label: t('category'),
                tooltip: {
                  title: t('category_info'),
                },
                required: true,
                disabled: !isSuperUser,
              }}
              loading={isCategoriesLoading}
              treeData={categoryTreeData}
              treeNodeFilterProp={'title'}
              allowClear
              showSearch
              treeDefaultExpandAll
              virtual={false}
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            />
            {dataSourceDetail?.conf?.restrict_created_products && (
              <AkinonCheckbox
                formItemProps={{
                  ...verticalFormItemLayout,
                  name: FormKey.RESTRICT_FOR_OTHERS,
                  control,
                  label: t('restrict_created_products'),
                  disabled: !canEditOffer,
                  tooltip: {
                    title: t('restrict_created_product_tooltip'),
                    style: { strokeColor: 'white' },
                  },
                  labelCol: {
                    xs: { span: 12 },
                    sm: { span: 12 },
                  },
                  wrapperCol: {
                    xs: { span: 1 },
                    sm: { span: 1 },
                  },
                }}
                className="pb-0"
              />
            )}
          </AkinonForm>
        </AkinonSpin>
      </Box>
      <Space style={{ width: '100%' }} direction="vertical" size="large">
        <Box
          className="box-primary form-box"
          title={t('product_summary', { amount: 1 })}
          subtitle={
            isSuperUser
              ? t('products_to_be_added_form_description_mpo')
              : t('products_to_be_added_form_description')
          }
        >
          <AkinonForm isFlex>
            {variantDynamicFields?.map(({ key, label, data_type, ...props }) => (
              <Col span={11} key={key}>
                <AkinonSpin spinning={isPreOfferLoading}>
                  <DynamicFormElement
                    formItemProps={{
                      control,
                      ...verticalFormItemLayout,
                      name: `attributes.${key}`,
                      label: label || key,
                      isWithColumn: true,
                    }}
                    {...props}
                    widget={DataTypeWidgetMap[data_type]}
                    placeholder={label || key}
                  />
                </AkinonSpin>
              </Col>
            ))}
            {variantListAttributeFields?.map(({ key, label, data_type, ...rest }) => {
              const props =
                data_type === DataType.DROPDOWN ? { options: get(rest, 'options', []) } : {};
              return (
                <Col span={11} key={key}>
                  <AkinonSpin spinning={isPreOfferLoading}>
                    <DynamicFormElement
                      key={key}
                      formItemProps={{
                        control,
                        ...verticalFormItemLayout,
                        name: `attributes.${key}`,
                        label: label || key,
                        required: rest.required,
                        disabled: !canEditOffer,
                      }}
                      placeholder={label || key}
                      widget={DataTypeWidgetMap[data_type]}
                      {...props}
                    />
                  </AkinonSpin>
                </Col>
              );
            })}

            <Col span={11}>
              <AkinonSpin spinning={isPreOfferLoading}>
                <AkinonInput
                  formItemProps={{
                    ...verticalFormItemLayout,
                    control,
                    name: FormKey.SKU,
                    label: t('sku'),
                    required: true,
                    disabled: !canEditOffer,
                  }}
                  placeholder={t('sku')}
                  {...getFormItemErrorMessage({ error: preOfferError })}
                />
                <PluginRenderer
                  placeholderId="ui-protocol-product-detail-sku"
                  params={{ sku: preOffer?.sku }}
                />
              </AkinonSpin>
            </Col>
            <Col span={11}>
              <AkinonSpin spinning={isPreOfferLoading}>
                <AkinonInput
                  formItemProps={{
                    ...verticalFormItemLayout,
                    control,
                    name: FormKey.SELLER_BARCODE,
                    label: t('seller_barcode'),
                    required: true,
                    disabled: !canEditOffer,
                  }}
                  placeholder={t('seller_barcode')}
                  {...getFormItemErrorMessage({ error: preOfferError })}
                />
              </AkinonSpin>
            </Col>
            <Col span={11}>
              <AkinonSpin spinning={isPreOfferLoading}>
                <AkinonInput
                  formItemProps={{
                    ...verticalFormItemLayout,
                    control,
                    name: FormKey.STOCK,
                    label: t('stock'),
                    required: true,
                    disabled: isSuperUser || !canEditOffer,
                  }}
                  placeholder={t('stock')}
                  {...getFormItemErrorMessage({ error: preOfferError })}
                />
              </AkinonSpin>
            </Col>
            <Col span={11}>
              <AkinonSpin spinning={isPreOfferLoading}>
                <AkinonInput
                  formItemProps={{
                    ...verticalFormItemLayout,
                    control,
                    name: FormKey.PRICE,
                    label: t('selling.price'),
                    required: true,
                    disabled: isSuperUser || !canEditOffer,
                  }}
                  placeholder={t('selling.price')}
                  {...getFormItemErrorMessage({ error: preOfferError })}
                />
              </AkinonSpin>
            </Col>
            <Col span={11}>
              <AkinonSpin spinning={isPreOfferLoading}>
                <AkinonInput
                  formItemProps={{
                    ...verticalFormItemLayout,
                    control,
                    name: FormKey.RETAIL_PRICE,
                    label: t('psf'),
                    required: true,
                    disabled: isSuperUser || !canEditOffer,
                  }}
                  placeholder={t('psf')}
                  {...getFormItemErrorMessage({ error: preOfferError })}
                />
              </AkinonSpin>
            </Col>
            <Col span={11}>
              <AkinonSpin spinning={isBuyBoxPriceLoading}>
                <AkinonInput
                  formItemProps={{
                    ...verticalFormItemLayout,
                    control,
                    name: FormKey.REFERENCE_PRICE,
                    label: (
                      <TitleWithHint text={t('reference_price')} hint={t('reference_price_desc')} />
                    ),
                    disabled: true,
                  }}
                  placeholder={t('reference_price')}
                  {...getFormItemErrorMessage({ error: buyboxPriceError })}
                />
              </AkinonSpin>
            </Col>
            <Col span={11}>
              <AkinonSpin spinning={isLowestPriceLoading}>
                <AkinonInput
                  formItemProps={{
                    ...verticalFormItemLayout,
                    control,
                    name: FormKey.LOWEST_PRICE,
                    label: (
                      <TitleWithHint
                        style={{ color: 'white' }}
                        text={t('lowest_price')}
                        hint={t('lowest_price_desc')}
                      />
                    ),
                    disabled: true,
                  }}
                  placeholder={t('lowest_price')}
                  {...getFormItemErrorMessage({ error: lowestPriceError })}
                />
              </AkinonSpin>
            </Col>
            <Col span={11}>
              <AkinonSpin spinning={isPreOfferLoading}>
                <AkinonInput
                  formItemProps={{
                    ...verticalFormItemLayout,
                    control,
                    name: FormKey.TAX_RATE,
                    label: t('tax_rate'),
                    required: true,
                    disabled: !canEditOffer,
                  }}
                  placeholder={t('tax_rate')}
                />
              </AkinonSpin>
            </Col>
            <Col span={11}>
              <AkinonSpin spinning={isPreOfferLoading}>
                <AkinonSelect
                  formItemProps={{
                    ...verticalFormItemLayout,
                    control,
                    name: FormKey.CURRENCY_TYPE,
                    label: t('currency'),
                    required: true,
                    disabled: !canEditOffer,
                  }}
                  loading={isProductPricesOptionsLoading}
                  options={currencyOptions}
                  placeholder={t('currency')}
                  {...getFormItemErrorMessage({ error: preOfferError })}
                />
              </AkinonSpin>
            </Col>
          </AkinonForm>
        </Box>
        <Box
          className="box-primary form-box"
          title={t('product.images.title')}
          subtitle={t('product.images.description.short')}
        >
          <ProductImageUpload
            minImageDimensions={minImageDimensions}
            maxImageDimensions={maxImageDimensions}
            accept="image/*"
            multiple
          />
        </Box>
        <Show when={isSuperUser}>
          <RelatedProductsTable categories={categories} preOffer={preOffer} />
        </Show>
        <Box
          className="box-primary form-box description-form"
          title="Description"
          subtitle={<div>{t('product.description.form.description')}</div>}
        >
          <AkinonFormItem
            control={control}
            label={t('product.description')}
            name={FormKey.SELLER_DESCRIPTION}
            wrapperCol={verticalFormItemLayout.wrapperCol}
            required
          >
            <QuillEditor readOnly={!canEditOffer} />
          </AkinonFormItem>
        </Box>
        <RejectModal
          isRejectModalVisible={isRejectModalVisible}
          setRejectModalVisible={setIsRejectModalVisible}
          isSubmitPreOfferMutating={isSubmitPreOfferMutating}
          priceLists={priceLists}
          stockLists={stockLists}
          dataSources={dataSources}
        />
        <SubmitForm
          isPatchPreOffersMutating={isPatchPreOffersMutating}
          isSubmitPreOfferMutating={isSubmitPreOfferMutating}
          isApprovePreOfferMutating={isApprovePreOfferMutating}
          isRejected={isRejected}
          onSubmit={onHandleSubmit}
          canEditOffer={canEditOffer}
        />
      </Space>
      <CreateProductModal
        modalProps={{
          open: isCreateProductModalVisible,
          onCancel: () => setIsCreateProductModalVisible(false),
        }}
        buttonProps={{
          onClick: () => createProduct({ preOfferId: offerId }),
          loading: isCreatingProduct,
        }}
      />
      <WithdrawModal
        preOffer={preOffer}
        isWithdrawModalVisible={isWithdrawModalVisible}
        onClose={() => {
          setIsWithdrawModalVisible(false);
        }}
      />
      <ResolveRejectionModal
        isResolveRejectionModalVisible={isResolveRejectionModalVisible}
        preOffer={preOffer}
        onClose={onResolveRejectionClose}
      />
    </section>
  );
};

export default OfferDetail;
