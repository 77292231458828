import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getCancellationRequestsUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getDataTableColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

export default function CancellationRequests() {
  const { t } = useTranslation('Orders');
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const navigate = useAppNavigate();
  const [pagination, setPagination] = usePagination();
  const filterFields = useFilterFields({ t });
  const filters = useFilterParams({ filterFields });

  const { dataSource, isDataSourceLoading, total } = useDataSource({
    pagination,
    filters,
  });

  useBreadcrumbs();
  usePageMeta();

  const dataTableColumns = getDataTableColumns({ t, isSuperUser });

  const getOnRowClickHandler = (record) => () => {
    navigate(RouteUrls.order.orderDetail, { orderId: record?.order?.id });
  };

  return (
    <section>
      <AkinonFilter title={t('filter')} filterFields={filterFields} total={total} />
      <Divider />
      <AkinonTable
        dataSource={dataSource}
        optionsUrl={getCancellationRequestsUrl}
        title={t('orders')}
        columns={dataTableColumns}
        rowKey="remote_id"
        pagination={pagination}
        setPagination={setPagination}
        loading={isDataSourceLoading}
        total={total}
        description={`${total} ${t('results.found')}`}
        onRow={(record) => ({
          onClick: getOnRowClickHandler(record),
        })}
      />
    </section>
  );
}
