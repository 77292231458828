import { getAddAllProductsStatusURL } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import round from 'lodash/round';
import { useEffect, useState } from 'react';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';
import { queryClient } from '../queryClient';

const DEFAULT_INTERVAL_DURATION = 3 * 1000; // 3 seconds
export const MAX_PROGRESS = 100;

export function useGetAddAllProductsToCollectionStatusQuery({
  cacheKey,
  queryOptions,
  collectionId,
} = {}) {
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const { data, isFetching } = useAkinonQuery({
    queryFn: () => {
      return client.get(getAddAllProductsStatusURL, {
        urlParams: {
          cacheKey,
        },
      });
    },
    enabled: Boolean(cacheKey),
    queryKey: [QueryKey.ADD_ALL_PRODUCTS_IMPORT_STATUS, cacheKey],
    refetchInterval: shouldRefetch ? DEFAULT_INTERVAL_DURATION : false,
    ...queryOptions,
  });

  const progressPercentage = data
    ? ((data?.state?.succeeded + data?.state?.failed) / data?.state?.total) * 100
    : 0;

  useEffect(() => {
    if (progressPercentage === 100) {
      queryClient.invalidateQueries([QueryKey.COLLECTION_ITEMS, { collection: collectionId }]);
      queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
    }
    const waitingForEFile = (progressPercentage === 100) & !data?.efile && data?.state?.failed;
    if (data?.state?.total === 0) {
      setShouldRefetch(false);
    } else {
      setShouldRefetch(isNaN(progressPercentage) || progressPercentage < 100 || waitingForEFile);
    }
  }, [progressPercentage]);

  return {
    addingAllStatus: data,
    addingAllStatusPercentage: round(progressPercentage, 2),
    isFetchingAddingAllStatus: isFetching,
  };
}
