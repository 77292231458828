import './styles.scss';

import { getImageResourceUrl, ImageExtension } from '@common/image';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import { openNotification } from '@components/AkinonNotification';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@hooks/useAppNavigate';
import { usePostResetPasswordMutation } from '@services/hooks/common/usePostResetPasswordMutation';
import { Divider, Form, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { forgotPasswordFormResolver, FormMetaKey } from './common';

const { Title, Text } = Typography;

const ForgotPassword = () => {
  const navigate = useAppNavigate();
  const { t } = useTranslation('Authentication');
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: forgotPasswordFormResolver,
  });
  const { sendResetPasswordMail, isSendingResetPasswordMail } = usePostResetPasswordMutation();

  const onSubmit = (formValues) => {
    sendResetPasswordMail(
      {
        requestBody: {
          email: formValues[FormMetaKey.EMAIL],
        },
        requestConfig: {
          suppressedNotifications: ['success'],
        },
      },
      {
        onSuccess() {
          openNotification({
            message: t('password_reset_sent'),
            description: t('forgotPassword.passwordResetMailSent'),
          });
          navigate(RouteUrls.public.signIn);
        },
      }
    );
  };

  return (
    <section className="forgot-password">
      <Title level={1} className="forgot-password__app-name">
        {t('seller.center').toUpperCase()}
      </Title>
      <div className="forgot-password__form-box  box-primary form-box boxWrapper">
        <div className="forgot-password__form-box-header">
          <Title level={2} className="forgot-password__title">
            {t('forgot.password')}
          </Title>
          <Divider className="forgot-password__form-box-header-divider" />
          <div className="forgot-password__form-box-header-flag" />
        </div>
        <AkinonForm layout="vertical" className="forgot-password__form">
          <AkinonFormItem label={t('email')} name={FormMetaKey.EMAIL} control={control} required>
            <AkinonInput placeholder={t('email')} />
          </AkinonFormItem>
          <Form.Item wrapperCol={{ span: 24 }}>
            <AkinonButton
              loading={isSendingResetPasswordMail}
              htmlType="button"
              onClick={handleSubmit(onSubmit)}
              type="primary"
              className="forgot-password__form-submit-button"
            >
              {t('send_reset_request').toUpperCase()}
            </AkinonButton>
          </Form.Item>
        </AkinonForm>
      </div>
      <footer className="forgot-password__footer">
        <img
          className="forgot-password__footer-logo"
          src={getImageResourceUrl({
            name: 'akinon-logo',
            extension: ImageExtension.SVG,
          })}
          alt="Akinon Logo"
        />
        <Text className="forgot-password__footer-text">
          {t('layout.footer.copyRight', {
            year: new Date().getFullYear(),
          })}
        </Text>
      </footer>
    </section>
  );
};

export default ForgotPassword;
