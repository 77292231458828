import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonDatePicker from '@components/AkinonDatePicker';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonSpin from '@components/AkinonSpin';
import { useSalesReportsQuery } from '@services/api/hooks/useSalesReportsQuery';
import clsx from 'clsx';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportType, StaticFilterKey } from '../common';
import CategoryBasedTable from './CategoryBasedTable';
import ProductBasedTable from './ProductBasedTable';

const InfoBox = ({ title, value }) => (
  <div className="flex flex-col bg-card-color p-3 justify-center items-center rounded-lg flex-1">
    <span className="text-spanish-gray">{title}</span>
    <span className="text-white text-[30px] font-semibold">{value}</span>
  </div>
);

const Period = {
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'thisWeek',
  THIS_MONTH: 'thisMonth',
};

const SalesTab = {
  PRODUCT_BASED: 'productBased',
  CATEGORY_BASED: 'categoryBased',
};

const defaultStartDate = moment().subtract(6, 'months').toISOString();
const defaultEndDate = moment().toISOString();

export default function SaleReports({ params, reportType }) {
  const { t } = useTranslation('Reporting');
  const [startDate, setStartDate] = useState(moment().subtract(6, 'months'));
  const [endDate, setEndDate] = useState(moment());
  const [period, setPeriod] = useState('yesterday');
  const [activeTab, setActiveTab] = useState(SalesTab.PRODUCT_BASED);
  const filters = usePageFilters();

  const { report: salesReports, isReportFetching: isSalesReportsFetching } = useSalesReportsQuery({
    params: {
      ...params,
      [StaticFilterKey.DATE_GTE]: startDate ? startDate.toISOString() : defaultStartDate,
      [StaticFilterKey.DATE_LTE]: endDate ? endDate.toISOString() : defaultEndDate,
      type: 'summary',
    },
    filters,
  });

  const { sales_amount, sales_count, sales_quantity } = salesReports ?? {
    sales_amount: 0,
    sales_count: 0,
    sales_quantity: 0,
  };

  const handlePeriodChange = (period) => {
    setPeriod(period);
    switch (period) {
      case 'yesterday':
        setStartDate(moment().subtract(2, 'day'));
        setEndDate(moment().subtract(1, 'day'));
        break;
      case 'thisWeek':
        setStartDate(moment().startOf('week'));
        setEndDate(moment().endOf('week'));
        break;
      case 'thisMonth':
        setStartDate(moment().startOf('month'));
        setEndDate(moment().endOf('month'));
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col gap-3 w-full flex-wrap">
      <div className="flex items-center w-full border-b border-b-police-blue pb-2 justify-between">
        <span className="relative text-lg text-white after:join-[absolute,w-full,h-1,bg-info,-bottom-4,left-0,inline-flex] ">
          {t('sale_reports.stepper_sales_title')}
        </span>
        <div className="flex items-center gap-2">
          <div className="flex gap-2">
            <AkinonDatePicker value={startDate} onChange={(date) => setStartDate(date)} />
            <AkinonDatePicker value={endDate} onChange={(date) => setEndDate(date)} />
          </div>
          <div className="flex items-center gap-2">
            <AkinonButton
              type="text"
              className={clsx({
                '!border !border-solid !border-info': period === Period.YESTERDAY,
              })}
              onClick={() => handlePeriodChange(Period.YESTERDAY)}
            >
              {t('sale_reports.stepper_sales_filters_period_today')}
            </AkinonButton>
            <AkinonButton
              type="text"
              className={clsx({
                '!border !border-solid !border-info': period === Period.THIS_WEEK,
              })}
              onClick={() => handlePeriodChange(Period.THIS_WEEK)}
            >
              {t('sale_reports.stepper_sales_filters_period_this_week')}
            </AkinonButton>
            <AkinonButton
              type="text"
              className={clsx({
                '!border !border-solid !border-info': period === Period.THIS_MONTH,
              })}
              onClick={() => handlePeriodChange(Period.THIS_MONTH)}
            >
              {t('sale_reports.stepper_sales_filters_period_this_month')}
            </AkinonButton>
          </div>
        </div>
      </div>
      <AkinonSpin spinning={isSalesReportsFetching}>
        <div className="flex w-full gap-2">
          {reportType === ReportType.SALES && (
            <InfoBox title={t('sale_reports.number_of_orders').toUpperCase()} value={sales_count} />
          )}
          {reportType === ReportType.REFUND && (
            <InfoBox
              title={t('sale_reports.number_of_refunds').toUpperCase()}
              value={sales_count}
            />
          )}
          {reportType === ReportType.CANCEL && (
            <InfoBox
              title={t('sale_reports.number_of_cancels').toUpperCase()}
              value={sales_count}
            />
          )}
          {reportType === ReportType.SALES && (
            <InfoBox
              title={t('sale_reports.total_order_amount').toUpperCase()}
              value={sales_amount}
            />
          )}
          {reportType === ReportType.REFUND && (
            <InfoBox
              title={t('sale_reports.total_refund_amount').toUpperCase()}
              value={sales_amount}
            />
          )}
          {reportType === ReportType.CANCEL && (
            <InfoBox
              title={t('sale_reports.total_cancel_amount').toUpperCase()}
              value={sales_amount}
            />
          )}
          {reportType === ReportType.SALES && (
            <InfoBox title={t('sale_reports.sold_quantity').toUpperCase()} value={sales_quantity} />
          )}
          {reportType === ReportType.REFUND && (
            <InfoBox
              title={t('sale_reports.refund_quantity').toUpperCase()}
              value={sales_quantity}
            />
          )}
          {reportType === ReportType.CANCEL && (
            <InfoBox
              title={t('sale_reports.cancel_quantity').toUpperCase()}
              value={sales_quantity}
            />
          )}
        </div>
      </AkinonSpin>

      <div className="flex w-full flex-wrap gap-2 border-b border-b-police-blue pb-3.5 mt-8">
        <AkinonButton
          className={clsx('text-[20px]', {
            'after:join-[absolute,w-full,h-1,bg-info,-bottom-4,left-0,inline-flex]':
              activeTab === SalesTab.PRODUCT_BASED,
          })}
          type="text"
          onClick={() => setActiveTab(SalesTab.PRODUCT_BASED)}
        >
          {t('sale_reports.product_title')}
        </AkinonButton>
        <AkinonButton
          className={clsx('text-[20px]', {
            'after:join-[absolute,w-full,h-1,bg-info,-bottom-4,left-0,inline-flex]':
              activeTab === SalesTab.CATEGORY_BASED,
          })}
          type="text"
          onClick={() => setActiveTab(SalesTab.CATEGORY_BASED)}
        >
          {t('sale_reports.category_title')}
        </AkinonButton>
      </div>
      {activeTab === SalesTab.PRODUCT_BASED && (
        <ProductBasedTable params={params} reportType={reportType} />
      )}
      {activeTab === SalesTab.CATEGORY_BASED && (
        <CategoryBasedTable params={params} reportType={reportType} />
      )}
    </div>
  );
}
