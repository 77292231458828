import AkinonButton from '@components/AkinonButton';
import Modal from '@components/Modal/Modal';
import { IconTrash } from '@tabler/icons-react';
import { dateFormatter } from '@utils/index';
import { Tag } from 'antd';

const QuickReplyStatus = {
  ACTIVE: true,
  PASSIVE: false,
};

export const DataIndex = {
  TITLE: 'title',
  CREATED_AT: 'createdAt',
  IS_ACTIVE: 'is_active',
  ID: 'id',
};

const getStatusRenderMap = ({ t }) => {
  return {
    [QuickReplyStatus.ACTIVE]: {
      color: 'green',
      label: t('active'),
    },
    [QuickReplyStatus.PASSIVE]: {
      color: 'red',
      label: t('passive'),
    },
  };
};

export const getColumns = ({ t, deleteQuickReply }) => [
  {
    dataIndex: DataIndex.TITLE,
    title: t('title'),
  },
  {
    dataIndex: DataIndex.CREATED_AT,
    title: t('created_at'),
    render: (cellData) => dateFormatter(cellData),
  },
  {
    dataIndex: DataIndex.IS_ACTIVE,
    width: 100,
    title: t('status'),
    render: (cellData) => {
      const { color, label } = getStatusRenderMap({ t })[cellData] ?? {};
      return <Tag color={color}>{label}</Tag>;
    },
  },
  {
    dataIndex: null,
    width: 100,
    title: t('actions'),
    className: 'flex justify-center',
    render: (_, { id, title, can_be_deleted }) => (
      <AkinonButton
        icon={<IconTrash size={16} stroke={1} className="self-center justify-self-center" />}
        type="ghost"
        className="border-columbia-blue flex w-full px-2"
        onClick={(event) => {
          event.stopPropagation();
          Modal.confirm({
            title: t('delete_quick_reply'),
            content: `${title} - ${t('delete_quick_reply_confirmation')}`,
            onOk: () => {
              deleteQuickReply({ id });
            },
          });
        }}
        disabled={!!can_be_deleted}
      />
    ),
  },
];
