import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonSelect from '@components/AkinonSelect';
import Box from '@components/utility/box';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { Language } from '@constants/locale';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLanguage } from '@root/hooks/useLanguage';
import { Typography } from 'antd';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getFormSchema } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const { Title } = Typography;

const LanguageSettings = () => {
  const { t } = useTranslation('Profile');

  const { language, setLanguage, isPatchingMe, isLanguageReady } = useLanguage();
  const languageOptions = Object.values(Language).map((lang) => ({
    label: t(`languages.${lang}`),
    value: lang,
  }));

  const schema = getFormSchema();

  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (isLanguageReady) {
      const initialLanguage = languageOptions?.find(
        (option) => option?.value === language?.toLowerCase()
      );
      reset({
        language: initialLanguage?.value,
      });
    }
  }, [isLanguageReady]);

  useBreadcrumbs();
  usePageMeta();

  const onSubmit = (formValues) => {
    const { language } = formValues;
    setLanguage(language);
  };

  return (
    <section className="language-settings">
      <Box className="box-primary form-box boxWrapper max-w-[800px]">
        <div className="language-settings__header">
          <div className="language-settings__header-flag" />
          <Title level={2} className="language-settings__header-title">
            {t('languageSettingsPage.editLanguageSettings')}
          </Title>
        </div>
        <AkinonForm
          control={control}
          onFinish={handleSubmit(onSubmit)}
          className="language-settings__form"
          schema={schema}
          layout="vertical"
          {...verticalFormItemLayout}
        >
          <AkinonSelect
            name="language"
            label={t('panelLanguage')}
            options={languageOptions.map((option) => ({
              label: option.label,
              value: option.value,
            }))}
          />
          <div className="language-settings__form-footer">
            <AkinonButton
              className="language-settings__form-submit-button w-100"
              htmlType="submit"
              loading={isPatchingMe}
            >
              {t('save').toUpperCase()}
            </AkinonButton>
          </div>
        </AkinonForm>
      </Box>
    </section>
  );
};

export default LanguageSettings;
