import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getProductOffersUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { useIsMobile } from '@hooks/useIsMobile';
import { usePaginatedProductOffersQuery } from '@services/api/hooks';
import { useCollectionItemsQuery } from '@services/api/hooks/useCollectionItemsQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { map } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';

import {
  getCollectionProductsColumns,
  getCollectionProductsTableActions,
  getMappedProductOffers,
} from '../common';

const CampaignProducts = ({
  collectionId,
  collectionDetail,
  filters,
  deleteProductFromCollectionAsync,
  onShowDeleteItemsModal,
  onRow,
  isAddingProductToCollection,
  isDeletingProductFromCollection,
}) => {
  const { t } = useTranslation(['Marketing', 'ProductsAndCategories']);
  const isMobile = useIsMobile();

  const [pagination, setPagination] = usePagination();

  const { productOffers, totalProductOffers } = usePaginatedProductOffersQuery({
    pagination,
    filters,
    params: {
      in_collection: collectionId,
    },
    queryOptions: {
      enabled: !isNil(collectionId),
    },
  });

  const productIds = map(productOffers, 'product') ?? [];

  const { products: collectionProducts, isCollectionProductsFetching } = useProductsQuery({
    params: {
      limit: productIds?.length,
      id__in: productIds?.join(','),
    },
    queryOptions: {
      enabled: productIds?.length > 0,
    },
  });

  const collectionProductOfferIds = uniq(reject(map(productOffers, 'id'), isNil));
  const { collectionItems, isFetchingCollectionItems } = useCollectionItemsQuery({
    queryOptions: {
      enabled: !isEmpty(collectionProductOfferIds) && !isNil(collectionId),
    },
    params: {
      offer__in: collectionProductOfferIds.join(','),
      limit: collectionProductOfferIds.length,
      collection: collectionId,
    },
  });

  const mappedProductOffers = getMappedProductOffers({
    collectionItems,
    collectionProducts,
    productOffers,
  });

  const collectionProductsTableColumns = getCollectionProductsColumns({
    t,
    deleteProductFromCollectionAsync,
    isMobile,
  });

  const collectionProductsTableActions = getCollectionProductsTableActions({
    t,
    onShowDeleteItemsModal,
  });

  return (
    <AkinonTable
      title={collectionDetail?.name || t('collection_items')}
      columns={collectionProductsTableColumns}
      dataSource={mappedProductOffers}
      optionsUrl={getProductOffersUrl}
      loading={
        isCollectionProductsFetching ||
        isFetchingCollectionItems ||
        isDeletingProductFromCollection ||
        isAddingProductToCollection
      }
      total={totalProductOffers}
      rowKey={defaultRowKey}
      pagination={pagination}
      setPagination={setPagination}
      tableActions={collectionProductsTableActions}
      rowSelection={true}
      description={`${totalProductOffers || 0} ${t('results.found')}`}
      onRow={onRow}
    />
  );
};

export default CampaignProducts;
