import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import { DraggerInputWithLabel } from '@components/AkinonDragger';
import AkinonFlex from '@components/AkinonFlex';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonSpin from '@components/AkinonSpin';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { Color } from '@constants/theme';
import { useUser } from '@root/contexts/hooks/useUser';
import { useAttachFileWithDataSourceIdMutation } from '@services/api/hooks/useAttachFileWithDataSourceIdMutation';
import { useDataSourceDetailQuery } from '@services/api/hooks/useDataSourceDetailQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import isArray from 'lodash/isArray';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import AgreementBox from './components/AgreementBox';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';

const DocumentsForm = () => {
  const { t } = useTranslation('Finance');
  const { control, handleSubmit } = useForm();
  const user = useUser();
  const userRole = useUserRole();
  const { id } = useParams();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const dataSourceId = id ?? user?.datasource;
  const { dataSourceDetail, isFetchingDataSourceDetail, refetchDataSourceDetail } =
    useDataSourceDetailQuery({
      dataSourceId,
      queryOptions: {
        enabled: Boolean(dataSourceId),
      },
    });

  const { attachFileWithDataSourceId, isAttachingFileToDataSource } =
    useAttachFileWithDataSourceIdMutation({
      mutationOptions: {
        onSuccess: refetchDataSourceDetail,
      },
    });

  const uploadedFiles = Object.entries(dataSourceDetail?.extras?.files ?? {}).reduce(
    (acc, [key, value]) => {
      acc[key] = {
        base64Data: isArray(value)
          ? value.map((file, index) => ({ url: file, uid: `${key}-${index}` }))
          : [{ url: value, uid: key }],
        file: { name: key },
      };
      return acc;
    },
    {}
  );

  const commonProps = {
    multiple: false,
    beforeUpload: () => {
      return false;
    },
    showUploadList: {
      showRemoveIcon: true,
    },
    type: 'file',
    maxCount: 1,
    onRemove: () => {},
  };

  const getDefaultList = (key) => {
    return uploadedFiles?.[key]?.base64Data?.map((f) => ({
      name: f.url || f.uid,
      url: f.url,
    }));
  };

  const handleDocumentSubmit = ({ extras }) => {
    const documentFormValues = extras?.files;

    for (const fileType in documentFormValues) {
      const isThereAnyFilesToUpload = documentFormValues[fileType]?.fileList?.length > 0;

      if (isThereAnyFilesToUpload) {
        const requestBody = new FormData();
        documentFormValues[fileType]?.fileList?.forEach((file) => {
          if (file.originFileObj) requestBody.append('files', file.originFileObj);
        });
        requestBody.append('file_type', fileType);

        attachFileWithDataSourceId({
          requestBody,
          dataSourceId: dataSourceId,
          requestConfig: {
            successMessage: t('transaction_success'),
            successDescription: t('changes_saved'),
            errorMessage: t('transaction_failed'),
            errorDescription: t('changes_error'),
          },
        });
      }
    }
  };

  useBreadcrumbs();

  return (
    <section className="seller-management-detail">
      <Show when={isSuperUser}>
        <Box className="seller-management-detail__header">
          <div className="seller-management-detail__header-info">
            <div>
              {t('seller')}: {dataSourceDetail?.name}
            </div>
          </div>
        </Box>
      </Show>
      <AkinonFlex className="gap-6 flex-col md:flex-row">
        <AkinonBox
          title={isSuperUser ? t('documents') : t('documents_seller')}
          description={isSuperUser ? t('documents_description') : t('documents_description_seller')}
          bannerColor={Color.WILD_WATERMELON}
          shadow
          className="box-primary form-box seller-management-detail__form-box"
        >
          <AkinonSpin spinning={isFetchingDataSourceDetail || isAttachingFileToDataSource}>
            {!isFetchingDataSourceDetail && (
              <AkinonForm layout="vertical" {...verticalFormItemLayout}>
                <AkinonFormItem
                  name="extras.files.company_form"
                  label={t('current_form')}
                  control={control}
                >
                  <DraggerInputWithLabel
                    placeholder={t('current_form')}
                    defaultFileList={getDefaultList('company_form')}
                    aria-label="company_form"
                    {...commonProps}
                  />
                </AkinonFormItem>
                <AkinonFormItem
                  name="extras.files.signature_circular"
                  label={t('signature_circulars')}
                  control={control}
                >
                  <DraggerInputWithLabel
                    placeholder={t('signature_circulars')}
                    defaultFileList={getDefaultList('signature_circular')}
                    aria-label="signature_circular"
                    {...commonProps}
                  />
                </AkinonFormItem>
                <AkinonFormItem
                  name="extras.files.tax_certificate"
                  label={t('trade_sign')}
                  control={control}
                >
                  <DraggerInputWithLabel
                    placeholder={t('trade_sign')}
                    defaultFileList={getDefaultList('tax_certificate')}
                    aria-label="tax_certificate"
                    {...commonProps}
                  />
                </AkinonFormItem>
                <AkinonFormItem
                  name="extras.files.activity_certificate"
                  label={t('operating_certificate')}
                  control={control}
                >
                  <DraggerInputWithLabel
                    placeholder={t('operating_certificate')}
                    defaultFileList={getDefaultList('activity_certificate')}
                    aria-label="activity_certificate"
                    {...commonProps}
                  />
                </AkinonFormItem>
                <AkinonFormItem
                  name="extras.files.commercial_registry_gazette"
                  label={t('registry_gazette')}
                  control={control}
                >
                  <DraggerInputWithLabel
                    placeholder={t('registry_gazette')}
                    defaultFileList={getDefaultList('commercial_registry_gazette')}
                    aria-label="commercial_registry_gazette"
                    {...commonProps}
                  />
                </AkinonFormItem>
                <AkinonButton
                  htmlType="submit"
                  type="primary"
                  loading={isAttachingFileToDataSource}
                  onClick={handleSubmit(handleDocumentSubmit)}
                >
                  {t('submit').toUpperCase()}
                </AkinonButton>
              </AkinonForm>
            )}
          </AkinonSpin>
        </AkinonBox>
        {userRole === UserRole.SELLER && <AgreementBox />}
      </AkinonFlex>
    </section>
  );
};

export default DocumentsForm;
