import './styles.scss';

import useFiltersSlice from '@components/AkinonFilter/hooks/store/useFiltersSlice';
import { WidgetType } from '@constants/commontypes';
import { overallDateFormat } from '@constants/index';
import useAppNavigate from '@hooks/useAppNavigate';
import { getTreeNodeByValue } from '@utils/index';
import { Col, Row, Space, Typography } from 'antd';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ActiveFilterItem from '../ActiveFilterItem';

const { Text } = Typography;

const mapDisplayValues = (t, filterFields, key, value) => {
  const field = filterFields.find((field) => field.name === key);
  let displayValue = field?.options?.find?.((option) => option?.value === value)?.label ?? value;

  if (field?.widget === WidgetType.DATE_PICKER) {
    displayValue = displayValue ? moment(displayValue)?.format?.(overallDateFormat) : displayValue;
  } else if (field?.widget === WidgetType.CHECKBOX_GROUP) {
    displayValue = displayValue?.map(() => t('yes')).join(', ');
  } else if (field?.widget === WidgetType.ASYNC_SELECT) {
    displayValue = get(displayValue, 'label', displayValue);
  } else if (field?.widget === WidgetType.TREESELECT) {
    const tree = field?.treeData;
    const label = getTreeNodeByValue({ tree, nodeValue: value })?.path;
    displayValue = label;
  }

  return [key, displayValue];
};

const ActiveFilters = ({ form, filterFields, actions }) => {
  const { t } = useTranslation();
  const { pageFilters } = useFiltersSlice();
  const { pathname } = useLocation();
  const { setValue, reset, control } = form;
  const formValues = useWatch({ control });
  const navigate = useAppNavigate();

  const [nonEmptyFilters, setNonEmptyFilters] = useState([]);

  const removeQueryParams = () => navigate(pathname, { replace: true });

  const handleOnResetFilters = useCallback(() => {
    reset(Object.keys(form.getValues()).reduce((acc, key) => ({ ...acc, [key]: null }), {}));
    if (pageFilters?.q) removeQueryParams();
  }, [pageFilters]);

  const handleOnRemoveFilter = (currentFilterKey) => {
    setValue(currentFilterKey, null);
    if (currentFilterKey === 'q') removeQueryParams();
  };

  const updateNonEmptyFilters = debounce((_filterFields) => {
    if (isEmpty(filterFields)) return;
    const filters = Object.entries(formValues)
      ?.filter(([_, filterValue]) => !isNil(filterValue))
      .map(([key, value]) => mapDisplayValues(t, _filterFields, key, value));
    setNonEmptyFilters(filters);
  }, 700);

  useEffect(() => {
    const sanitizeValues = (obj) =>
      mapValues(obj, (value) => (isNil(value) || value === '' ? null : value));

    const sanitizedFilters = sanitizeValues(formValues);
    if (Object.values(sanitizedFilters).every((value) => isNil(value))) {
      setNonEmptyFilters([]);
      return;
    }

    updateNonEmptyFilters(filterFields);
    return () => {
      updateNonEmptyFilters.cancel();
    };
  }, [formValues, filterFields]);

  return (
    <div className="active-filters-container">
      <Row align="middle" className="active-filters">
        <Col className="active-filters__list">
          <Space>
            <Text className="active-filters__label">{t('active.filters')}</Text>
            <Space className="active-filters__items">
              {nonEmptyFilters?.map(([filterKey, filterValue]) => {
                const activeFilterField = filterFields.find((field) => field.name === filterKey);

                return (
                  <ActiveFilterItem
                    key={filterKey}
                    filterKey={filterKey}
                    filterValue={filterValue}
                    activeFilterField={activeFilterField}
                    filterLabel={activeFilterField?.placeholder ?? activeFilterField?.label}
                    handleOnRemoveFilter={handleOnRemoveFilter}
                  />
                );
              })}
            </Space>
          </Space>
        </Col>
        <Col className="active-filters__clear-filters">
          <i
            onClick={handleOnResetFilters}
            className="icon-bin active-filters__reset-filters-icon"
          />
        </Col>
      </Row>
      {actions}
    </div>
  );
};

export default ActiveFilters;
