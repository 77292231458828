import './style.scss';

import noImage from '@assets/images/noimage.png';
import basicStyle from '@common/theme/basicStyle';
import Icon from '@components/Intrinsic';
import Box from '@components/utility/box/index';
import {
  MOCK_GRAPH_VALUES_SELLER_RATING,
  MOCK_GRPAH_LABELS,
  TOP_FIVE_PRODUCTS,
} from '@pages/Dashboard/common';
import { Image, Skeleton, Tooltip as AntdTooltip } from 'antd';
import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

const DataNotFound = ({ t }) => {
  return (
    <div className="dataWarning">
      <Icon className="icon-warning_triangle" />
      <span>{t('data.not.found')}</span>
    </div>
  );
};

const BigCardWithSkeleton = ({ children, loading, skeletonRows = 5 }) => {
  return (
    <Skeleton
      loading={loading}
      active
      title={false}
      className="seller-performance__big-card-with-skeleton"
      round
      paragraph={{
        rows: skeletonRows,
        width: '100%',
      }}
    >
      {children}
    </Skeleton>
  );
};

const SellerPerformance = () => {
  const { t } = useTranslation('Reporting');

  useBreadcrumbs();
  usePageMeta();

  const { rowStyle } = basicStyle;

  const createChart = () => {
    //const labels = chart.filter(Boolean).map((i) => i.time);
    //const values = chart.filter(Boolean).map((i) => i.count);

    const labels = MOCK_GRPAH_LABELS;
    const values = MOCK_GRAPH_VALUES_SELLER_RATING;
    const data = {
      labels,
      datasets: [
        {
          backgroundColor: 'rgba(98, 109, 144, 0.3)',
          borderColor: '#037cf9',
          borderWidth: 4,
          hoverBackgroundColor: 'rgba(98, 109, 144, 1)',
          hoverBorderColor: 'rgba(98, 109, 144, 1)',
          strokeColor: '#fff',
          lineTension: 0.1,
          yAxisID: 'yAxes',
          xAxisID: 'xAxes',
          data: values,
          fill: true,
        },
      ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: {
          axis: 'y',
          grid: {
            display: false,
          },
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
        xAxes: {
          axis: 'x',
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        filler: {
          propagate: false,
        },
        tooltip: {
          mode: 'x',
          intersect: false,
          callbacks: {
            label: (tooltipItem) =>
              t('total.order.count.with.no', {
                totalOrders: tooltipItem.formattedValue,
              }),
          },
        },
      },
    };

    return <Line data={data} options={options} />;
  };

  const chartInfoRender = () => {
    const responsiveChart = createChart();
    return <Box className="chart-wrapper">{responsiveChart}</Box>;
  };

  const totalProductAndStockRender = () => {
    return (
      <div className="totalProductAndStock-wrapper">
        <div className="card-box--item">
          <div className="card-box--header"> {t('seller.rating').toUpperCase()} </div>
          <div className="card-box--content">4,2</div>
        </div>
        <div className="card-box--item">
          <div className="card-box--header">{t('deliver.time.to.cargo').toUpperCase()} </div>
          <div className="card-box--content">2 {t('day')}</div>
        </div>
        <div className="card-box--item">
          <div className="card-box--header">{t('rating.score').toUpperCase()} </div>
          <div className="card-box--content">12.345</div>
        </div>
      </div>
    );
  };

  const overallInfoRender = () => {
    return (
      <>
        <div className="card-box--item">
          <div className="card-box--header"> {t('response.rate').toUpperCase()} </div>
          <div className="card-box--content">%97</div>
        </div>
        <div className="card-box--item">
          <div className="card-box--header"> {t('cancel.return.rate').toUpperCase()} </div>
          <div className="card-box--content">%3</div>
        </div>
      </>
    );
  };

  return (
    <section className="seller-performance">
      <div className="sale-channel">
        <div className="left-side">
          <span className="sale-channel-title"> {t('sales.performance')} </span>
        </div>
      </div>

      <div className="box-container">
        <div className="channel-card-box-wrapper">
          <div className="card-box--container">{totalProductAndStockRender()}</div>
        </div>
        <div className="box-container--chart">
          <div className="chart-head">
            <span className="chart-name"> {t('seller.rating.last.three.month')} </span>
          </div>
          {chartInfoRender()}
        </div>
      </div>

      <div className="box-container" style={rowStyle}>
        <div className="box-container--item">
          <div className="card-head--title">
            <span className="card-name product">{t('top.five.product')}</span>
          </div>
          <Box className="box-item">
            <div className="card-head">
              <div className="card-head--desc"> {t('product').toUpperCase()} </div>
              <div className="card-head--value"> {t('total.sales').toUpperCase()} </div>
            </div>
            <BigCardWithSkeleton>
              {TOP_FIVE_PRODUCTS?.length > 0 ? (
                TOP_FIVE_PRODUCTS?.slice(0, 5).map((item, index) => {
                  return (
                    <div className="card-item" key={index}>
                      <div className="card--title">
                        <Image className="card-item-image" src={noImage} /> {item.name}
                      </div>
                      <div className="card--value"> {item.product_count} </div>
                    </div>
                  );
                })
              ) : (
                <DataNotFound t={t} />
              )}
            </BigCardWithSkeleton>
          </Box>
        </div>

        <div className="box-container--item">
          <div className="card-head--title">
            <span className="card-name category">{t('top.rated.products')}</span>
          </div>
          <Box className="box-item">
            <div className="card-head">
              <div className="card-head--desc"> {t('category').toUpperCase()} </div>
              <div className="card-head--value"> {t('total.sales').toUpperCase()} </div>
            </div>
            <BigCardWithSkeleton>
              {TOP_FIVE_PRODUCTS?.length > 0 ? (
                TOP_FIVE_PRODUCTS?.slice(0, 5).map((item, index) => {
                  return (
                    <div className="card-item" key={index}>
                      <div className="card--title">
                        <Image className="card-item-image" src={noImage} />
                        <AntdTooltip title={item.name}>{item.name}</AntdTooltip>
                      </div>
                      <div className="card--value"> {item.product_rate}</div>
                    </div>
                  );
                })
              ) : (
                <DataNotFound t={t} />
              )}
            </BigCardWithSkeleton>
          </Box>
        </div>
        <div className="box-container--item">
          <div className="channel-card-box-wrapper">
            <div className="card-box--container">{overallInfoRender()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

SellerPerformance.defaultProps = {
  startDate: new Date(),
  endDate: new Date(),
};
export default SellerPerformance;
