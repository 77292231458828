import { findElementByInnerText } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import { openNotification } from '@components/AkinonNotification';
import ImageTableHead from '@components/ImageTableHead';
import Modal from '@components/Modal/Modal.jsx';
import Show from '@components/Show/index.jsx';
import SmallColumnImage from '@components/SmallColumnImage';
import { defaultDateTimeFormat } from '@constants';
import { OrderStatus, PackageStatus, WidgetType } from '@constants/commontypes';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { Color } from '@constants/theme.js';
import { zodResolver } from '@hookform/resolvers/zod';
import { ProductStatus, StatusText } from '@pages/Orders/common';
import i18n from '@root/i18n';
import { IconPackage } from '@tabler/icons-react';
import { Tag, Tooltip, Typography } from 'antd';
import { rgba } from 'polished';
import { Fragment } from 'react';
import styled from 'styled-components';
import * as z from 'zod';

const { Title, Text } = Typography;

export const ShippingMethodSlug = {
  OTHER: 'other',
};

const CenteredRowFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CustomText = styled(Text)`
  color: white;
`;

const ModalWarningBox = styled.div`
  background-color: ${rgba(Color.LIMA, 0.1)};
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid ${Color.LIMA};
  margin-bottom: 16px;
  font-weight: normal;
`;

const DataIndex = {
  PRODUCT_IMAGE: 'product_image',
  ATTRIBUTES: 'attributes',
  SELLER_BASECODE: 'SELLER_BASECODE',
  SKU: 'sku',
  SELLER_DESCRIPTION: 'SELLER_DESCRIPTION',
  SHIPPING_COMPANY: 'shipping_company',
  TRACKING_NUMBER: 'tracking_number',
  INVOICE_NUMBER: 'invoice_number',
  SELLER_BARCODE: 'SELLER_BARCODE',
  PRODUCT_NAME: ['product', 'name'],
  STATUS: 'status',
};

const getOrderStatusRenderMap = ({ t }) => {
  return {
    [OrderStatus.APPROVE_WAITING]: {
      label: t('waiting_approval'),
      tagColor: 'blue',
    },
    [OrderStatus.APPROVED]: {
      label: t('approved'),
      tagColor: 'geekblue',
    },
    [OrderStatus.PACKAGE_WAITING]: {
      label: t('waiting_package'),
      tagColor: 'gold',
    },
    [OrderStatus.PACKAGED]: {
      label: t('packaged'),
      tagColor: 'magenta',
    },
    [OrderStatus.SHIPPED]: {
      label: t('shipped'),
      tagColor: 'purple',
    },
    [OrderStatus.DELIVERY_FAILED]: {
      label: t('delivery_failed'),
      tagColor: 'red',
    },
    [OrderStatus.DELIVERED]: {
      label: t('delivered'),
      tagColor: 'green',
    },
    [OrderStatus.WAITING_FOR_CANCELLATION]: {
      label: t('waiting_cancel_return'),
      tagColor: 'volcano',
    },
    [OrderStatus.CANCELLED]: {
      label: t('cancelled'),
      tagColor: 'red',
    },
  };
};

export const getPackageProductsColumns = ({ t, getProductClickHandler }) => {
  const orderStatusRenderMap = getOrderStatusRenderMap({ t });
  return [
    {
      dataIndex: DataIndex.PRODUCT_IMAGE,
      title: <ImageTableHead />,
      render: (productImage) => <SmallColumnImage src={productImage} preview={true} />,
      align: 'center',
    },
    {
      dataIndex: DataIndex.PRODUCT_NAME,
      title: t('product_name'),
      render: (productName, record) => (
        <AkinonButton type="link" onClick={getProductClickHandler(record)}>
          <Tooltip title={productName}>{productName}</Tooltip>
        </AkinonButton>
      ),
    },
    {
      dataIndex: [DataIndex.ATTRIBUTES, DataIndex.SELLER_BASECODE],
      title: t('basecode'),
      render: (basecode) => <Tooltip title={basecode}>{basecode}</Tooltip>,
    },
    {
      dataIndex: [DataIndex.ATTRIBUTES, DataIndex.SELLER_BARCODE],
      title: t('barcode'),
      render: (barcode) => <Tooltip title={barcode}>{barcode}</Tooltip>,
    },
    {
      dataIndex: DataIndex.SKU,
      title: t('offer_code'),
      render: (sku) => <Tooltip title={sku}>{sku}</Tooltip>,
    },
    {
      dataIndex: DataIndex.STATUS,
      title: t('status'),
      render(orderItemStatus) {
        const orderStatusRenderItem = orderStatusRenderMap[orderItemStatus];
        return <Tag color={orderStatusRenderItem?.tagColor}>{orderStatusRenderItem?.label}</Tag>;
      },
    },
  ];
};

export const getActionButtons = ({ t, packageDetail, onCreatePackage }) => {
  const createNewPackageActionHandler = (selectedOrderItems) => {
    const validOrderItems = selectedOrderItems.filter(
      (orderItem) =>
        orderItem.status === OrderStatus.APPROVED ||
        orderItem.status === OrderStatus.PACKAGE_WAITING
    );
    const numberOfInvalidOrderItems = selectedOrderItems.length - validOrderItems.length;
    const areAllOrderItemsInvalid = validOrderItems.length === 0;
    if (areAllOrderItemsInvalid) {
      openNotification({
        type: 'warning',
        message: t('invalid_operation'),
        description: t('create_package_invalid_operation'),
      });
      return;
    }
    Modal.confirm({
      content: (
        <section>
          <Show when={numberOfInvalidOrderItems > 0}>
            <ModalWarningBox>
              {t('create_new_package_modal_info', {
                totalNumberOfOrderItems: selectedOrderItems.length,
                numberOfInvalidOrderItems,
              })}
            </ModalWarningBox>
          </Show>
          {t('sure_to_create_package_selected_items')}
        </section>
      ),
      onOk() {
        onCreatePackage(validOrderItems.map((orderItem) => orderItem.id));
      },
      closable: true,
      icon: null,
      cancelButtonProps: {
        className: 'akinon-button',
        type: 'text',
      },
      okButtonProps: {
        className: 'akinon-button',
      },
      title: (
        <CenteredRowFlex>
          <IconPackage color={Color.LIMA} />
          <CustomText>{t('pack')}</CustomText>
        </CenteredRowFlex>
      ),
    });
  };

  switch (packageDetail?.status) {
    case PackageStatus.PREPARING:
      return [
        {
          label: t('create_new_package_for_selected'),
          isSelectionRequired: true,
          actionHandler: createNewPackageActionHandler,
          id: 1,
        },
      ];

    case PackageStatus.FAILED:
      return [
        {
          label: t('create_new_package_for_selected'),
          isSelectionRequired: true,
          actionHandler: createNewPackageActionHandler,
        },
      ];

    default:
      return [];
  }
};

export const CargoInfoModalFormKey = {
  CARGO_COMPANY: 'cargo_company',
  SHIPPING_COMPANY: 'shipping_company',
  CARGO_TRACKING_NUMBER: 'cargo_tracking_number',
  CARGO_TRACKING_URL: 'cargo_tracking_url',
};

export const getCargoInfoModalConditionalFormMeta = ({ packageDetail = {}, t }) => [
  {
    key: CargoInfoModalFormKey.CARGO_TRACKING_NUMBER,
    widget: WidgetType.INPUT,
    formItemProps: {
      label: t('cargo_tracking_number'),
      required: true,
    },
    placeholder: t('cargo_tracking_number'),
    conditional: true,
    initialValue: packageDetail?.tracking_number,
  },
  {
    key: CargoInfoModalFormKey.SHIPPING_COMPANY,
    widget: WidgetType.INPUT,
    formItemProps: {
      label: t('shipping.company'),
    },
    placeholder: t('shipping.company'),
    conditional: true,
  },
  {
    key: CargoInfoModalFormKey.CARGO_TRACKING_URL,
    widget: WidgetType.INPUT,
    formItemProps: {
      label: t('cargo_tracking_url'),
    },
    placeholder: t('cargo_tracking_url'),
    conditional: true,
    initialValue: packageDetail?.tracking_url,
  },
];

export const InvoiceDataType = {
  URL: 'url',
  HTML: 'html',
  BASE64: 'base64',
  TEXT: 'text',
};

export const invoiceDataTypeOptions = ({ t }) => [
  {
    label: t('invoice_data_type_url'),
    value: InvoiceDataType.URL,
  },
  {
    label: t('invoice_data_type_html'),
    value: InvoiceDataType.HTML,
  },
  {
    label: t('invoice_data_type_base64'),
    value: InvoiceDataType.BASE64,
  },
  {
    label: t('invoice_data_type_text'),
    value: InvoiceDataType.TEXT,
  },
];

export const InvoiceInfoModalFormMetaKey = {
  DATA_TYPE: 'invoice_data_type',
  INVOICE_DATA: 'invoice_data',
  INVOICE_NUMBER: 'invoice_number',
  INVOICE_DATE: 'invoice_date',
  TRACKING_NUMBER: 'cargo_tracking_number',
  TRACKING_URL: 'cargo_tracking_url',
};

export const shippAllFormResolver = zodResolver(
  z.object({
    [CargoInfoModalFormKey.CARGO_TRACKING_NUMBER]: z.string().min(1),
    [CargoInfoModalFormKey.SHIPPING_COMPANY]: z.string().optional(),
    [CargoInfoModalFormKey.CARGO_TRACKING_URL]: z
      .union([
        z.literal(''),
        z
          .string()
          .trim()
          .url({ message: i18n.t('url.validation.message') }),
      ])
      .optional(),
  })
);

export const invoiceInfoFormResolver = zodResolver(
  z.object({
    [InvoiceInfoModalFormMetaKey.INVOICE_DATA]: z
      .string()
      .url({
        message: i18n.t('url.validation.message'),
      })
      .min(1),
    [InvoiceInfoModalFormMetaKey.INVOICE_NUMBER]: z.string().min(1),
    [InvoiceInfoModalFormMetaKey.INVOICE_DATE]: z.coerce.date(),
  })
);

export const getInvoiceInfoModalFormMeta = ({ t }) => ({
  formItemLayout: verticalFormItemLayout,
  colon: true,
  fields: [
    {
      key: InvoiceInfoModalFormMetaKey.INVOICE_DATA,
      widget: WidgetType.INPUT,
      formItemProps: {
        label: t('invoice_data_url'),
        required: true,
      },
      placeholder: t('invoice_data_url'),
    },
    {
      key: InvoiceInfoModalFormMetaKey.INVOICE_NUMBER,
      widget: WidgetType.INPUT,
      placeholder: t('invoice_number'),
      formItemProps: {
        label: t('invoice_number'),
        required: true,
      },
    },
    {
      key: InvoiceInfoModalFormMetaKey.INVOICE_DATE,
      widget: WidgetType.DATE_PICKER,
      widgetProps: {
        showTime: { format: 'HH:mm' },
        format: defaultDateTimeFormat,
      },
      placeholder: t('select_variable', { variable: t('invoice_date') }),
      formItemProps: {
        label: t('invoice_date'),
        required: true,
      },
    },
  ],
});

export const getChangeCargoCompanyParams = ({
  formValues,
  shippingOptionList,
  cargoOtherOption,
}) => {
  const cargoCompanyData = shippingOptionList.find((item) => item.id === formValues.cargo_company);
  const isCompanyIsOther = cargoCompanyData?.id === cargoOtherOption?.id;
  const paramsMap = {
    default: {
      shipping_method: cargoCompanyData?.id,
    },
    other: {
      tracking_url: formValues.cargo_tracking_url,
      tracking_number: formValues.cargo_tracking_number,
    },
  };

  return {
    ...paramsMap.default,
    ...(isCompanyIsOther ? paramsMap.other : {}),
  };
};

export { ProductStatus, StatusText };

export const getTourSteps = ({ t, isActionButtonsVisible }) => {
  return [
    {
      selector: '#package_detail_show_actions',
      content: (
        <Fragment>
          <Title level={4}>{t('package_detail_guide_step_1_title')}</Title>
          <Text>{t('package_detail_guide_step_1_desc')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '#package_detail_other_packages_info',
      content: (
        <Fragment>
          <Title level={4}>{t('package_detail_guide_step_2_title')}</Title>
          <Text>{t('package_detail_guide_step_2_desc')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.standard-akinon-table-header__title',
      content: (
        <Fragment>
          <Title level={4}>{t('package_detail_guide_step_3_title')}</Title>
          <Text>{t('package_detail_guide_step_3_desc')}</Text>
        </Fragment>
      ),
    },
    isActionButtonsVisible && {
      selector: '.akinon-table-actions__select',
      content: (
        <Fragment>
          <Title level={4}>{t('package_detail_guide_step_4_title')}</Title>
          <Text>{t('package_detail_guide_step_4_desc')}</Text>
        </Fragment>
      ),
    },
    {
      selector: findElementByInnerText(t('product_name').toLocaleUpperCase(i18n.language), 'th'),
      content: (
        <Fragment>
          <Title level={4}>{t('package_detail_guide_step_5_title')}</Title>
          <Text>{t('package_detail_guide_step_5_desc')}</Text>
        </Fragment>
      ),
    },
  ].filter(Boolean);
};

export const getPackagesStatusRenderMap = ({ t }) => {
  return {
    [PackageStatus.CANCELED]: {
      label: t('cancelled'),
      tagColor: 'red',
    },
    [PackageStatus.PREPARING]: {
      label: t('preparing'),
      tagColor: 'yellow',
    },
    [PackageStatus.READY]: {
      label: t('ready_to_ship'),
      tagColor: 'blue',
    },
    [PackageStatus.SHIPPED]: {
      label: t('shipped'),
      tagColor: 'purple',
    },
    [PackageStatus.DELIVERED]: {
      label: t('delivered'),
      tagColor: 'green',
    },
    [PackageStatus.FAILED]: {
      label: t('failed'),
      tagColor: 'volcano',
    },
  };
};
