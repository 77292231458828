import { T } from '@utils/languageProvider';

export const httpMethods = {
  GET: 'GET',
  POST: 'POST',
  OPTIONS: 'OPTIONS',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const responseTypes = {
  success: 'success',
  fail: 'fail',
};

export const languages = {
  tr: 'tr',
  en: 'en',
};

export const pages = {
  '/': { title: 'Dashboard', description: '' },
  '/profile': { title: 'Profile', description: 'profileDescription' },
};

export const tagColors = {
  magenta: 'magenta',
  red: 'red',
  volcano: 'volcano',
  orange: 'orange',
  gold: 'gold',
  lime: 'lime',
  green: 'green',
  cyan: 'cyan',
  blue: 'blue',
  geekblue: 'geekblue',
  purple: 'purple',
};

export const labelAligns = {
  left: 'left',
  right: 'right',
};

export const rowSelectionTypes = {
  single: 'radio',
  multiple: 'checkbox',
};

export const buttonTypes = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  danger: 'danger',
  dashed: 'dashed',
};

export const primaryColors = {
  red: '#f97173',
  green: '#7fb6a1',
  blue: '#89b0f4',
  yellow: '#f8b033',
  gray: '#aaaeb7',
  pink: '#ff90aa',
  purple: '#a278c8',
  orange: '#ffa583',
};

export const secondaryColors = {
  light_red: '#fc9c9e',
  dark_red: '#c6484a',
  light_green: '#92d6bc',
  dark_green: '#508a73',
  light_blue: '#b0ccfc',
  dark_blue: '#6789c5',
  light_yellow: '#f9c97e',
  dark_yellow: '#d8880f',
  light_gray: '#cbd0db',
  dark_gray: '#7b7f8a',
};

export const colors = [
  primaryColors.gray,
  primaryColors.purple,
  primaryColors.yellow,
  secondaryColors.light_green,
  primaryColors.green,
  primaryColors.pink,
  secondaryColors.light_yellow,
  secondaryColors.dark_yellow,
  primaryColors.orange,
  primaryColors.blue,
  secondaryColors.light_red,
  secondaryColors.dark_blue,
  primaryColors.red,
  secondaryColors.dark_gray,
  secondaryColors.dark_red,
  secondaryColors.light_blue,
  secondaryColors.dark_green,
  secondaryColors.light_gray,
];

export const cancellationPlanStatuses = {
  COMPLETED: 'completed',
  WAITING: 'waiting',
  CONFIRMED: 'confirmed',
  CONFIRMATION_WAITING: 'confirmation_waiting',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  APPROVED: 'approved',
  WAITING_FOR_PAYMENT: 'waiting_for_payment',
  MANUEL_REFUND_NEED: 'manuel_refund_need',
  WAITING_SUPPLIER: 'waiting_supplier',
};

export const catalogMenu = [
  { key: 'product-list', value: '0' },
  { key: 'price-list', value: '1' },
  { key: 'stock-list', value: '2' },
  { key: 'category-tree', value: '3' },
  { key: 'check-list', value: '4' },
  { key: 'priority-list', value: '5' },
  { key: 'channels', value: '6' },
  { key: 'settings', value: '7' },
];

export const filterEnum = {
  contains: 'contains',
  startsWith: 'startsWith',
};

export const orderCancelColorsEnum = {
  waiting: 'orange',
  confirmation_waiting: 'orange',
  confirmed: 'green',
  approved: 'green',
  rejected: 'red',
  waiting_for_payment: 'orange',
  manuel_refund_need: 'orange',
  completed: 'green',
  cancelled: 'red',
  failed: 'red',
  waiting_supplier: 'orange',
  waiting_approval: 'orange',
  open: 'green',
};

export const orderCancelStatuses = ({ t }) => [
  { value: 'waiting', label: t('order_cancel_waiting'), color: 'orange' },
  { value: 'confirmation_waiting', label: t('confirmation.waiting'), color: 'orange' },
  { value: 'confirmed', label: t('confirmed'), color: 'green' },
  { value: 'approved', label: t('approved'), color: 'green' },
  { value: 'rejected', label: t('rejected'), color: 'red' },
  { value: 'waiting_for_payment', label: t('waiting.for.payment'), color: 'orange' },
  { value: 'manuel_refund_need', label: t('manuel.refund.need'), color: 'red' },
  { value: 'completed', label: t('completed'), color: 'green' },
  { value: 'cancelled', label: t('cancelled'), color: 'red' },
  { value: 'failed', label: t('failed'), color: 'red' },
  { value: 'waiting_supplier', label: t('waiting.supplier'), color: 'orange' },
  { value: 'waiting_approval', label: t('waiting_approval'), color: 'orange' },
  { value: 'open', label: t('order_cancel_open'), color: 'orange' },
];

export const orderStatusColorsEnum = {
  50: tagColors.orange, // waits for cancel
  100: tagColors.red, // cancelled
  200: tagColors.orange, // waiting
  300: tagColors.orange, // waits for payment
  350: tagColors.orange, // confirmation waiting
  400: tagColors.green, // approved
  450: tagColors.orange, // preparing
  500: tagColors.green, // shipped
  510: tagColors.green, // shipped and informed
  520: tagColors.green, // in store
  540: tagColors.red, // delivery failed
  544: tagColors.orange, // review started
  545: tagColors.orange, // review waiting
  546: tagColors.orange, // waiting for payment
  700: tagColors.red, // waiting for cancellation
  547: tagColors.green, // paid
  550: tagColors.green, // was delivered
  600: tagColors.green, // completed
  800: tagColors.red, // cancelled
  REFUND_WAITING: tagColors.orange,
};

export const OrderStatus = {
  APPROVE_WAITING: 100,
  APPROVED: 200,
  PACKAGE_WAITING: 300,
  PACKAGED: 400,
  SHIPPED: 500,
  DELIVERY_FAILED: 540,
  DELIVERED: 600,
  WAITING_FOR_CANCELLATION: 700,
  CANCELLED: 800,
};

export const getCancellationPlanStatuses = ({ t }) => [
  {
    pk: '0',
    value: 'completed',
    label: t('cancellation.plan.completed'),
  },
  {
    pk: '1',
    value: 'waiting',
    label: t('waiting'),
  },
  {
    pk: '2',
    value: 'confirmed',
    label: t('confirmed'),
  },
  {
    pk: '3',
    value: 'confirmation_waiting',
    label: t('confirmation.waiting'),
  },
  {
    pk: '4',
    value: 'rejected',
    label: t('rejected'),
  },
  {
    pk: '5',
    value: 'cancelled',
    label: t('cancelled'),
  },
  {
    pk: '6',
    value: 'failed',
    label: t('failed'),
  },
  { pk: '7', value: 'approved', label: t('approved') },
  { pk: '8', value: 'waiting_for_payment', label: t('waiting.for.payment') },
  { pk: '9', value: 'manuel_refund_need', label: t('manuel.refund.need') },
  { pk: '10', value: 'waiting_supplier', label: t('waiting.supplier') },
];

export const campaignConditionType = [
  {
    pk: '0',
    label: T('total.amount'),
    value: 'amount',
  },
  {
    pk: '1',
    label: T('total.quantity'),
    value: 'quantity',
  },
  {
    pk: '2',
    label: T('payment.step.discount'),
    value: 'query',
  },
  {
    pk: '3',
    label: T('data.source'),
    value: 'datasource',
  },
];

export const campaignBenefitType = [
  {
    pk: '0',
    label: T('amount.discount'),
    value: 'fixed',
  },
  {
    pk: '1',
    label: T('multibuy'),
    value: 'multibuy',
  },
  {
    pk: '2',
    label: T('percentage.discount'),
    value: 'percentage',
  },
  {
    pk: '3',
    label: T('shipping.free'),
    value: 'shipping_free',
  },
  {
    pk: '4',
    label: T('coupon'),
    value: 'coupon',
  },
  {
    pk: '5',
    label: T('voucher'),
    value: 'voucher',
  },
];

export const conditionPriceType = [
  {
    label: T('unit.price'),
    value: 'unit_price',
  },
  {
    label: T('discount.unit.price'),
    value: 'discount_unit_price',
  },
  {
    label: T('retail.price'),
    value: 'retail_price',
  },
].map((item, pk) => ({ ...item, pk }));

export const conditionConsumeType = [
  {
    pk: '0',
    label: T('consume.type.globally'),
    value: 'globally',
  },
  {
    pk: '1',
    label: T('consume.type.locally'),
    value: 'locally',
  },
  {
    pk: '2',
    label: T('consume.type.whatever'),
    value: 'whatever',
  },
];

export const yesNoOptions = [
  {
    pk: '0',
    value: 'True',
    label: T('yes'),
  },
  {
    pk: '1',
    value: 'False',
    label: T('no'),
  },
];

export const yesNoCrossOptions = [
  {
    pk: '0',
    value: 'False',
    label: T('yes'),
  },
  {
    pk: '1',
    value: 'True',
    label: T('no'),
  },
];

export const trueFalseOptions = [
  {
    pk: '0',
    value: 'true',
    label: T('yes'),
  },
  {
    pk: '1',
    value: 'false',
    label: T('no'),
  },
];

export const trueFalseBoolOptions = [
  {
    pk: '0',
    value: true,
    label: T('yes'),
  },
  {
    pk: '1',
    value: false,
    label: T('no'),
  },
];

export const trueFalseCrossBoolOptions = [
  {
    pk: '0',
    value: false,
    label: T('yes'),
  },
  {
    pk: '1',
    value: true,
    label: T('no'),
  },
];

export const activePassiveOptions = [
  {
    pk: '0',
    label: T('active'),
    value: 'active',
  },
  {
    pk: '1',
    label: T('passive'),
    value: 'passive',
  },
];

export const getActivePassiveOptions = ({ t }) => [
  {
    label: t('active'),
    value: 'active',
  },
  {
    label: t('passive'),
    value: 'passive',
  },
];

export const getStatusOptions = ({ t }) => [
  {
    label: t('active'),
    value: 1,
  },
  {
    label: t('passive'),
    value: 0,
  },
];

const ApprovalStatus = {
  SUBMITTED: 2,
  WAITING: 3,
  SENDING: 4,
};

export const getApprovalStatusOptions = ({ t }) => [
  {
    label: t('approval.submitted'),
    value: ApprovalStatus.SUBMITTED,
  },
  {
    label: t('approval.waiting'),
    value: ApprovalStatus.WAITING,
  },
];

export const activePassiveUsedOptions = [
  {
    pk: '0',
    label: T('active'),
    value: 'active',
  },
  {
    pk: '1',
    label: T('passive'),
    value: 'passive',
  },
  {
    pk: '3',
    label: T('used'),
    value: 'used',
  },
];

export const activePassiveStringBoolOptions = [
  {
    pk: '0',
    label: T('active'),
    value: 'True',
  },
  {
    pk: '1',
    label: T('passive'),
    value: 'False',
  },
];

export const sortingTypeStringOptions = [
  {
    pk: '0',
    label: T('static'),
    value: 'static',
  },
  {
    pk: '1',
    label: T('dynamic'),
    value: 'dynamic',
  },
  {
    pk: '2',
    label: T('external'),
    value: 'external',
  },
];

export const activePassiveBoolOptions = [
  {
    pk: '0',
    label: T('active'),
    value: 'true',
  },
  {
    pk: '1',
    label: T('passive'),
    value: 'false',
  },
];

export const activePassiveBooleanOptions = [
  {
    pk: '0',
    label: T('active'),
    value: true,
  },
  {
    pk: '1',
    label: T('passive'),
    value: false,
  },
];

export const getActivePassiveBooleanOptions = ({ t }) => [
  {
    label: t('active'),
    value: true,
  },
  {
    label: t('passive'),
    value: false,
  },
];

export const checkoutProviderGatewayOptions = [
  {
    pk: '0',
    label: 'Akifast',
    value: 'omnicore.libs.checkout_provider_gateways.akifast.AkifastCheckoutProviderGateway',
  },
];

export const selectCodeToValueOptions = (options) =>
  options &&
  options.map((option) => ({
    pk: option.pk,
    label: option.name,
    value: option.pk,
  }));

export const existencyOptions = [
  {
    pk: '0',
    value: 'True',
    label: T('exist'),
  },
  {
    pk: '1',
    value: 'False',
    label: T('not.exist'),
  },
];
export const existencyCrossOptions = [
  {
    pk: '0',
    value: 'False',
    label: T('exist'),
  },
  {
    pk: '1',
    value: 'True',
    label: T('not.exist'),
  },
];

export const datatableTitle = T('product.pool');

export const productTypes = {
  preproduct: '-1',
  simple: '0',
  variant: '1',
  bundle: '2',
  grouped: '3',
  premiscellaneous: '-2',
  miscellaneous: '4',
};

export const productTypeOptions = [
  {
    pk: '-1',
    value: '-1',
    label: T('product.type.preproduct'),
  },
  {
    pk: '0',
    value: '0',
    label: T('product.type.simple'),
  },
  {
    pk: '1',
    value: '1',
    label: T('product.type.variant'),
  },
  {
    pk: '2',
    value: '2',
    label: T('product.type.bundle'),
  },
  {
    pk: '3',
    value: '3',
    label: T('product.type.grouped'),
  },
  {
    pk: '-2',
    value: '-2',
    label: T('product.type.premiscellaneous'),
  },
  {
    pk: '4',
    value: '4',
    label: T('product.type.miscellaneous'),
  },
];

export const productTypeOptionsWithUrl = Array.from({ length: 7 }, (_, i) => `${i + -2}`).map(
  (el) => ({
    pk: el,
    url:
      el === '-1' ? '/integrations/pre-products' : '/products-and-categories/products/product-pool',
  })
);

export const paymentOptions = [
  {
    value: 'credit_card',
    label: T('credit.card'),
  },
  {
    value: 'funds_transfer',
    label: T('fund.transfer'),
  },
  {
    value: 'pay_on_delivery',
    label: T('pay.on.delivery'),
  },
  {
    value: 'bkm_express',
    label: T('bkm.express'),
  },
  {
    value: 'loyalty_money',
    label: T('loyalty.money'),
  },
  {
    value: 'cash_register',
    label: T('cash.register'),
  },
  {
    value: 'credit_payment',
    label: T('credit_payment'),
  },
  {
    value: 'gpay',
    label: T('gpay'),
  },
  {
    value: 'redirection',
    label: T('redirection'),
  },
  {
    value: 'stored_card',
    label: T('stored.card'),
  },
  {
    value: 'masterpass',
    label: T('masterpass'),
  },
].map((item, pk) => ({ ...item, pk }));

export const sessionOfferTypes = [
  {
    value: 'giz_eticaret_promotion',
    label: T('giz.e.commerce.promotion'),
  },
  {
    value: 'ayakkabidunyasi',
    label: T('ayakkabidunyasi'),
  },
  {
    value: 'zubizu',
    label: T('zubizu'),
  },
  {
    value: 'adunyasi_loyalty_card',
    label: T('a.dunyasi.loyalty.card'),
  },
  {
    value: 'sephora',
    label: T('sephora'),
  },
  {
    value: 'obase_promotion_gateway',
    label: T('obase.promotion.gateway'),
  },
  {
    value: 'rapid_promo',
    label: T('rapid.promo'),
  },
].map((item, pk) => ({ pk, ...item }));

export const cargoCompanies = [
  { value: 'aras', label: T('aras') },
  { value: 'ups', label: T('ups') },
  { value: 'asil', label: T('asil') },
  { value: 'yurtici', label: T('yurtici') },
  { value: 'mng', label: T('mng') },
  { value: 'hepsi_express', label: T('hepsi.express') },
  { value: 'aramex', label: T('aramex') },
  { value: 'other', label: T('others') },
  { value: 'ismail', label: T('ismail') },
  { value: 'gls', label: 'GLS Logistic' },
  { value: 'hoopkapida', label: T('hoopkapida') },
  { value: 'fancourier', label: T('fan.courier') },
  { value: 'tmmexpress', label: T('tmm.express') },
  { value: 'ptt', label: T('ptt') },
  { value: 'horoz', label: T('horoz') },
  { value: 'novaposhta', label: T('novaposhta') },
  { value: 'droplight', label: T('droplight') },
  { value: 'surat', label: T('surat') },
  { value: 'ups_intl', label: T('ups.intl') },
  { value: 'b2c_direct', label: T('b2c.direct') },
  { value: 'trendyol_express', label: T('trendyol.express') },
  { value: 'ay_cargo', label: T('ay.kargo') },
  { value: 'cainiao', label: T('cainiao') },
  { value: 'jetizz', label: T('jetizz') },
  { value: 'acs', label: T('acs') },
  { value: 'packupp', label: T('packupp') },
  { value: 'k_gelsin', label: T('k.gelsin') },
  { value: 'birgunde', label: T('birgunde') },
  { value: 'kargo_ist', label: T('kargo.ist') },
  { value: 'mylerz', label: T('mylerz') },
  { value: 'mylerzsdd', label: T('mylerzsdd') },
  { value: 'r2s', label: T('cargo.r2s') },
  { value: 'boomex', label: T('boomex') },
  { value: 'chrono', label: T('chrono') },
  { value: 'scotty', label: T('scotty') },
  { value: 'netkargo', label: T('netcargo') },
  { value: 'murat_lgc', label: T('murat.lgc') },
  { value: 'fast_cargo', label: T('fast.cargo') },
  { value: 'in_post', label: T('in.post') },
  { value: 'dpd', label: T('dpd') },
  { value: 'k_sende', label: T('kargomsende') },
  { value: 'loomis', label: T('loomis') },
  { value: 'carrtell', label: T('carrtell') },
  { value: 'speeta', label: T('speeta') },
  { value: 'bringo', label: T('bringo') },
].map((item, pk) => ({ pk, ...item }));

export const cancellationType = [
  {
    pk: '0',
    value: 'cancel',
    label: T('cancellation'),
  },
  {
    pk: '1',
    value: 'refund',
    label: T('refund'),
  },
];

export const deliveryOptionTypes = [
  {
    value: 'customer',
    label: T('customer'),
    pk: 'customer',
  },
  {
    value: 'retail_store',
    label: T('click.and.collect.1'),
    pk: 'retail_store',
  },
];

export const facetTypes = [
  {
    pk: '0',
    value: 'terms',
    label: T('facet.type.terms'),
  },
  {
    pk: '1',
    value: 'range',
    label: T('facet.type.range'),
  },
];

export const attributeTypes = [
  {
    pk: '0',
    value: 'global',
    label: T('global'),
  },
  {
    pk: '1',
    value: 'eav',
    label: T('eav'),
  },
];

export const widgetTypes = [
  {
    pk: '0',
    value: 'multi_select',
    label: T('multi.select'),
  },
  {
    pk: '1',
    value: 'category',
    label: T('category'),
  },
  {
    pk: '2',
    value: 'attribute_value',
    label: T('attribute.value'),
  },
  {
    pk: '3',
    value: 'visual_swatch',
    label: T('visual.swatch'),
  },
  {
    pk: '4',
    value: 'nested',
    label: T('nested'),
  },
];

export const cancellationReasons = [
  {
    pk: '0',
    value: '3',
    label: T('cancellation.reason.opt1'),
  },
  {
    pk: '1',
    value: '2',
    label: T('cancellation.reason.opt2'),
  },
  {
    pk: '2',
    value: '4',
    label: T('cancellation.reason.opt3'),
  },
  {
    pk: '3',
    value: '1',
    label: T('cancellation.reason.opt4'),
  },
  {
    pk: '4',
    value: '8',
    label: T('cancellation.reason.opt5'),
  },
  {
    pk: '5',
    value: '9',
    label: T('cancellation.reason.opt6'),
  },
  {
    pk: '6',
    value: '10',
    label: T('cancellation.reason.opt7'),
  },
  {
    pk: '7',
    value: '5',
    label: T('cancellation.reason.opt8'),
  },
];

export const retailStockGatewayTypes = [
  {
    value: 'ayakkabidunyasi',
    label: T('ayakkabidunyasi'),
    pk: '0',
  },
  {
    value: 'kigili',
    label: T('kigili'),
    pk: '1',
  },
  {
    value: 'desa',
    label: T('desa'),
    pk: '2',
  },
  {
    value: 'mns',
    label: T('mns'),
    pk: '3',
  },
  {
    value: 'lacoste',
    label: T('lacoste'),
    pk: '4',
  },
  {
    value: 'nebim',
    label: T('nebim'),
    pk: '5',
  },
  {
    value: 'gap',
    label: T('gap'),
    pk: '6',
  },
  {
    value: 'samsonite',
    label: T('samsonite'),
    pk: '7',
  },
  {
    value: 'mudo',
    label: T('mudo'),
    pk: '8',
  },
  {
    value: 'derimod',
    label: T('derimod'),
    pk: '9',
  },
  {
    value: 'enh',
    label: T('enh'),
    pk: '10',
  },
];

export const orderLogType = [
  {
    pk: '1',
    value: 'order_create',
    label: T('order.logtype.opt1'),
  },
  {
    pk: '2',
    value: 'order_single_status',
    label: T('order.logtype.opt2'),
  },
  {
    pk: '3',
    value: 'order_changed_status',
    label: T('order.logtype.opt3'),
  },
  {
    pk: '4',
    value: 'order_changed_single_status',
    label: T('order.logtype.opt4'),
  },
  {
    pk: '5',
    value: 'order_cancel',
    label: T('order.logtype.opt5'),
  },
];

export const serviceLogPathList = [
  {
    pk: '0',
    path: 'order-logs',
    value: 'order_single_status',
  },
  {
    pk: '1',
    path: 'price-logs',
    value: 'price_single ',
  },
  {
    pk: '2',
    path: 'product-logs',
    value: 'product_single',
  },
  {
    pk: '3',
    path: 'stock-logs',
    value: 'stock_single',
  },
];
export const transactionType = [
  {
    value: 'purchase',
    label: T('purchase'),
  },
  {
    value: 'purchase_three_d',
    label: T('purchase.3d'),
  },
  {
    value: 'void',
    label: T('cancel'),
  },
  {
    value: 'redirect',
    label: T('redirect'),
  },
  {
    value: 'refund',
    label: T('transaction.refund'),
  },
  {
    value: 'pre_purchase',
    label: T('pre.purchase'),
  },
].map((item, pk) => ({ ...item, pk }));

export const isSucceeded = [
  {
    pk: '0',
    value: 'True',
    label: T('successful'),
  },
  {
    pk: '1',
    value: 'False',
    label: T('unsuccessful'),
  },
];

export const isSucceededColumn = [
  {
    pk: '0',
    value: true,
    label: T('successful'),
  },
  {
    pk: '1',
    value: false,
    label: T('unsuccessful'),
  },
];

export const isSuperUser = ({ t }) => [
  {
    pk: '0',
    value: true,
    label: t('admin'),
  },
  {
    pk: '1',
    value: false,
    label: t('seller'),
  },
];

export const stockLogType = [
  {
    pk: '0',
    value: 'stock_single',
    label: T('stock.single'),
  },
  {
    pk: '1',
    value: 'stock_changed',
    label: T('stock.changed'),
  },
  {
    pk: '2',
    value: 'stock_changed_single',
    label: T('stock.changed.single'),
  },
];
export const cardPaymentType = [
  {
    pk: '0',
    value: 'credit',
    label: T('credit.card'),
  },
  {
    pk: '1',
    value: 'debit',
    label: T('debit.card'),
  },
];

export const clientTypes = [
  {
    pk: '0',
    value: 'default',
    label: T('default.client.type'),
  },
  {
    pk: '1',
    value: 'ios',
    label: T('ios.client.type'),
  },
  {
    pk: '2',
    value: 'android',
    label: T('android.client.type'),
  },
  {
    pk: '3',
    value: 'instore',
    label: T('instore.client.type'),
  },
];

export const orderTransactionTypes = [
  {
    pk: '0',
    value: 'purchase',
    label: T('purchase'),
  },
  {
    pk: '1',
    value: 'refund',
    label: T('refunding'),
  },
  {
    pk: '2',
    value: 'cancel',
    label: T('cancel'),
  },
];
export const productLogType = [
  {
    pk: '0',
    value: 'product_single',
    label: T('product.single'),
  },
  {
    pk: '1',
    value: 'product_changed',
    label: T('product.changed'),
  },
  {
    pk: '2',
    value: 'product_changed_single',
    label: T('product.changed.single'),
  },
];
export const successStatusOptions = [
  {
    pk: '0',
    value: '0',
    label: T('unsuccessful'),
  },
  {
    pk: '1',
    value: '1',
    label: T('successful'),
  },
];
export const priceLogType = [
  {
    pk: '0',
    value: 'price_single',
    label: T('price.single'),
  },
  {
    pk: '1',
    value: 'price_changed',
    label: T('price.changed'),
  },
  {
    pk: '3',
    value: 'price_changed_single',
    label: T('price.changed.single'),
  },
];
export const skuLogType = [
  {
    pk: '0',
    value: 'product_single',
    label: T('product.single'),
  },
  {
    pk: '1',
    value: 'product_changed',
    label: T('product.changed'),
  },
  {
    pk: '0',
    value: 'product_changed_single',
    label: T('product.changed.single'),
  },
  {
    pk: '0',
    value: 'price_single',
    label: T('price.single'),
  },
  {
    pk: '0',
    value: 'price_changed',
    label: T('price.changed'),
  },
  {
    pk: '0',
    value: 'price_changed_single',
    label: T('price.changed.single'),
  },
  {
    pk: '0',
    value: 'stock_single',
    label: T('stock.single'),
  },
  {
    pk: '0',
    value: 'stock_changed',
    label: T('stock.changed'),
  },
];

export const storeDays = [
  {
    pk: 0,
    value: 0,
    label: T('monday'),
  },
  {
    pk: 1,
    value: 1,
    label: T('tuesday'),
  },
  {
    pk: 2,
    value: 2,
    label: T('wednesday'),
  },
  {
    pk: 3,
    value: 3,
    label: T('thursday'),
  },
  {
    pk: 4,
    value: 4,
    label: T('friday'),
  },
  {
    pk: 5,
    value: 5,
    label: T('saturday'),
  },
  {
    pk: 6,
    value: 6,
    label: T('sunday'),
  },
];
export const channelTypes = [
  {
    value: 'hepsiburada',
    label: T('hepsiburada'),
  },
  {
    value: 'gittigidiyor',
    label: T('gittigidiyor'),
  },
  {
    value: 'n11',
    label: T('n11'),
  },
  {
    value: 'web',
    label: T('web'),
  },
  {
    value: 'feed',
    label: T('feed'),
  },
  {
    value: 'trendyol',
    label: T('trendyol'),
  },
  {
    value: 'morhipo',
    label: T('morhipo'),
  },
  {
    value: 'morhipo V2',
    label: T('morhipo.v2'),
  },
  {
    value: 'amazon',
    label: T('amazon'),
  },
  {
    value: 'lidyana',
    label: T('lidyana'),
  },
  {
    value: 'allegro',
    label: T('allegro'),
  },
  {
    value: 'unified',
    label: T('unified'),
  },
  {
    value: 'aliexpress',
    label: T('aliexpress'),
  },
  {
    value: 'gordumaldim',
    label: T('gordumaldim'),
  },
  {
    value: 'pazarama',
    label: T('pazarama'),
  },
  {
    value: 'flo',
    label: T('flo'),
  },
  {
    value: 'modanisa',
    label: T('modanisa'),
  },
  {
    value: 'sales_channel',
    label: T('sale.channel'),
  },
].map((item, pk) => ({ ...item, pk }));

export const priceListOption = [
  {
    pk: '0',
    value: 'true',
    label: T('has.erp.connection'),
  },
  {
    pk: '1',
    value: 'false',
    label: T('erp.connection.no'),
  },
];

export const filterButtons = [
  {
    id: 1,
    name: T('product.type.simple'),
    key: 'product_type',
    value: '0',
    img: 'simple',
  },
  {
    id: 2,
    name: T('product.type.variant'),
    key: 'product_type',
    value: '1',
    img: 'variant',
  },
  {
    id: 3,
    name: T('product.type.grouped'),
    key: 'product_type',
    value: '3',
    img: 'grouped',
  },
  {
    id: 4,
    name: T('product.type.bundle'),
    key: 'product_type',
    value: '2',
    img: 'bundle',
  },
  {
    id: 5,
    name: T('product.type.miscellaneous'),
    key: 'product_type',
    value: '4',
    img: 'misc',
  },
];

export const customerTypes = [
  {
    pk: 0,
    value: 'guest',
    label: T('guest.member'),
  },
  {
    pk: 1,
    value: 'registered',
    label: T('registered.member'),
  },
];

export const deliveryOption = [
  {
    pk: 0,
    value: 'customer',
    label: T('home.delivery'),
  },
  {
    pk: 1,
    value: 'retail_store',
    label: T('store.delivery'),
  },
];

export const acceptImage = ['.png', '.jpeg', '.jpg', '.bmp'];

export const reconciliationStatuses = [
  {
    value: 'waiting',
  },
  {
    value: 'started',
  },
  {
    value: 'finished',
  },
  {
    value: 'waiting_for_approval',
  },
  {
    value: 'sending_for_approval',
  },
  {
    value: 'waiting_for_modified',
  },
  {
    value: 'approved',
  },
  {
    value: 'completed',
  },
].map(({ value }, pk) => ({
  value,
  label: T('reconciliation_status.' + value),
  pk,
}));

export const googleMerchantAdapterTypes = [
  {
    value: 'base',
    label: 'base',
  },
  {
    value: 'aydinli',
    label: 'aydinli',
  },
  {
    value: 'pcardin',
    label: 'pcardin',
  },
  {
    value: 'samsung_out_of_stock',
    label: 'samsung_out_of_stock',
  },
  {
    value: 'samsung',
    label: 'samsung',
  },
  {
    value: 'superstep',
    label: 'superstep',
  },
  {
    value: 'spx_persona_click',
    label: 'spx_persona_click',
  },
  {
    value: 'barcin',
    label: 'barcin',
  },
  {
    value: 'bisse',
    label: 'bisse',
  },
  {
    value: 'tanca',
    label: 'tanca',
  },
  {
    value: 'kigili',
    label: 'kigili',
  },
  {
    value: 'mns',
    label: 'mns',
  },
  {
    value: 'mudo',
    label: 'mudo',
  },
  {
    value: 'gap',
    label: 'gap',
  },
  {
    value: 'lacoste',
    label: 'lacoste',
  },
  {
    value: 'desa',
    label: 'desa',
  },
  {
    value: 'base_persona_click',
    label: 'base_persona_click',
  },
  {
    value: 'sc_persona_click',
    label: 'sc_persona_click',
  },
  {
    value: 'sportive_persona_click',
    label: 'sportive_persona_click',
  },
  {
    value: 'a101_persona_click',
    label: 'a101_persona_click',
  },
  {
    value: 'damat_persona_click',
    label: 'damat_persona_click',
  },
  {
    value: 'sephora',
    label: 'sephora',
  },
  {
    value: 'sportive',
    label: 'sportive',
  },
  {
    value: 'coco',
    label: 'coco',
  },
  {
    value: 'a101',
    label: 'a101',
  },
  {
    value: 'adl',
    label: 'adl',
  },
  {
    value: 'faraway',
    label: 'faraway',
  },
  {
    value: 'etatpur',
    label: 'etatpur',
  },
  {
    value: 'evidea',
    label: 'evidea',
  },
  {
    value: 'base_cimri',
    label: 'base_cimri',
  },
  {
    value: 'tefal_cimri',
    label: 'tefal_cimri',
  },
  {
    value: 'uspa',
    label: 'uspa',
  },
  {
    value: 'damat_persona_click',
    label: 'damat_persona_click',
  },
  {
    value: 'base_related_mc',
    label: 'base_related_mc',
  },
  {
    value: 'spx_related_mc',
    label: 'spx_related_mc',
  },
].map((item, pk) => ({ ...item, pk }));

export const outOfStockEnums = ['520', '920', 'out_of_stock'];

export const uiVisibilityOptions = [
  {
    pk: '0',
    label: T('third.party'),
    value: 'third_party',
  },
  {
    pk: '1',
    label: T('oms.web'),
    value: 'oms_web',
  },
  {
    pk: '2',
    label: T('instore'),
    value: 'instore',
  },
];

export const extractionStrategies = [
  {
    label: T('default.listable'),
    value: 'omnishop.search.strategies.DefaultProductExtractionStrategy',
  },
  {
    label: T('first.item'),
    value: 'omnishop.search.strategies.FirstItemSearchExtractionStrategy',
  },
  {
    label: T('listable.first'),
    value: 'omnishop.search.strategies.ListableFirstProductExtractionStrategy',
  },
  {
    label: T('min.price.first'),
    value: 'omnishop.search.strategies.MinPriceFirstProductExtractionStrategy',
  },
  {
    label: T('all.sub.products'),
    value: 'omnishop.search.strategies.AllSubProductsExtractionStrategy',
  },
];

export const channelTypeNonWebPath = ['/marketing'];

export const externalStatuses = [
  {
    label: T('sap.cancelled'),
    value: 1,
  },
  {
    label: T('sap.returned'),
    value: 2,
  },
].map((item, pk) => ({ pk, ...item }));

export const urlConfigAttributes = [
  {
    value: 'pk',
    label: T('id'),
    includConfig: ['product', 'categorynode'],
  },
  {
    value: 'sku',
    label: T('sku'),
    includConfig: ['product'],
  },
  {
    value: 'base_code',
    label: T('base.code'),
    includConfig: ['product'],
  },
  {
    value: 'name',
    label: T('product.name'),
    includConfig: ['product', 'categorynode'],
  },
  {
    value: 'langugage_code',
    label: T('language.code'),
    includConfig: ['product', 'categorynode'],
  },
];

export const emailTemplateNames = [
  {
    label: T('email.template.text.base'),
    value: 'emails/text-base.html',
  },
  {
    label: T('email.template.confirmation.signup.message'),
    value: 'account/email/email_confirmation_signup_message.html',
  },
  {
    label: T('email.template.confirmation.signup.subject'),
    value: 'account/email/email_confirmation_signup_subject.txt',
  },
  {
    label: T('email.template.email.set.message'),
    value: 'account/email/email_set_primary_message.html',
  },
  {
    label: T('email.template.email.set.message.subject'),
    value: 'account/email/email_confirmation_subject.txt',
  },
  {
    label: T('email.template.password.reset.message'),
    value: 'registration/password_reset_email_as_html.html',
  },
  {
    label: T('email.template.password.reset.subject'),
    value: 'registration/password_reset_subject.txt',
  },
  {
    label: T('email.template.order.placed.message'),
    value: 'emails/orders/completed.html',
  },
  {
    label: T('email.template.order.placed.subject'),
    value: 'emails/orders/completed-subject.html',
  },
  {
    label: T('email.template.order.shipped.message'),
    value: 'emails/orders/cargo.html',
  },
  {
    label: T('email.template.order.shipped.subject'),
    value: 'emails/orders/cargo-subject.html',
  },
  {
    label: T('email.template.order.cancellation.message'),
    value: 'emails/orders/cancel-request.html',
  },
  {
    label: T('email.template.order.cancellation.subject'),
    value: 'emails/orders/cancel-subject-request.html',
  },
  {
    label: T('email.template.order.refund.message'),
    value: 'emails/orders/refund-request.html',
  },
  {
    label: T('email.template.order.refund.subject'),
    value: 'emails/orders/refund-subject-request.html',
  },
  {
    label: T('email.template.order.delivered.message'),
    value: 'emails/orders/order-delivered.html',
  },
  {
    label: T('email.template.order.delivered.subject'),
    value: 'emails/orders/order-delivered-subject.html',
  },
  {
    label: T('email.template.order.canceled.message'),
    value: 'emails/orders/cancellation.html',
  },
  {
    label: T('email.template.order.canceled.subject'),
    value: 'emails/orders/cancellation-subject.html',
  },
  {
    label: T('email.template.supply.process.message'),
    value: 'emails/promotions/coupon.html',
  },
  {
    label: T('email.template.supply.process.subject'),
    value: 'emails/promotions/coupon-subject.html',
  },
  {
    label: T('email.template.stock.alert.message'),
    value: 'emails/wishlists/stock-alert.html',
  },
  {
    label: T('email.template.stock.alert.subject'),
    value: 'emails/wishlists/stock-alert-subject.html',
  },
  {
    label: T('email.template.unavailable.product.coupon.message'),
    value: 'emails/promotions/not-available-product-coupon.html',
  },
  {
    label: T('email.template.unavailable.product.coupon.subject'),
    value: 'emails/promotions/not-available-product-coupon-subject.html',
  },
  {
    label: T('email.template.price.alert.message'),
    value: 'emails/wishlists/price-alert.html',
  },
  {
    label: T('email.template.price.alert.subject'),
    value: 'emails/wishlists/price-alert-subject.html',
  },
].map((item, pk) => ({ pk, ...item }));

export const deliveryType = [
  {
    label: T('standard'),
    value: 'standard',
  },
  {
    label: T('same.day.delivery'),
    value: 'same_day',
  },
  {
    label: T('next.day.delivery'),
    value: 'next_day',
  },
  {
    label: T('in.store.delivery'),
    value: 'in_store',
  },
];

export const reportingTypes = [
  {
    pk: '0',
    label: T('sales.report'),
    value: 'sales',
  },
  {
    pk: '1',
    label: T('order.report'),
    value: 'order',
  },
];

export const defaultReportWidgetTypes = Object.freeze({
  choice: 'select',
  integer: 'number',
  string: 'input',
  date: 'date-picker',
  pk_field: 'select',
});

export const priceTypes = {
  unit_price: T('unit.price'),
  discount_unit_price: T('discount.unit.price'),
  retail_price: T('retail.price'),
};

export const accountIdRelatedCargoOptions = [
  {
    value: 'aras',
    label: 'Aras Kargo',
  },
  {
    value: 'k_gelsin',
    label: 'Kolay Gelsin',
  },
].map((item, index) => ({
  ...item,
  pk: `${index}`,
}));

export const stockLocationTypeOptions = [
  { label: T('warehouse'), value: 'warehouse' },
  { label: T('store'), value: 'store' },
].map((item, index) => ({
  ...item,
  pk: `${index}`,
}));

export const accountIdRelatedCargoKeySuffix = '_sender_address_id';

export const entryTypes = [
  {
    pk: '0',
    label: T('entry.types.0'),
    value: '0123456789',
  },
  {
    pk: '1',
    label: T('entry.types.1'),
    value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  },
  {
    pk: '3',
    label: T('entry.types.2'),
    value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
  },
  {
    pk: '4',
    label: T('entry.types.3'),
    value: 'custom',
  },
];

export const HttpMethod = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH',
  OPTIONS: 'OPTIONS',
};

export const WidgetType = {
  SELECT: 'select',
  ASYNC_SELECT: 'async-select',
  TIME: 'time',
  TREESELECT: 'tree-select',
  TEXTAREA: 'textarea',
  INPUT: 'input',
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUP: 'checkbox-group',
  DATE_PICKER: 'date-picker',
  SWITCH: 'switch',
  NUMBER: 'number',
  FILE: 'file',
  CODE: 'code',
  IMAGE: 'image',
  EDITOR: 'editor',
  CUSTOM: 'custom',
  LIST: 'list',
  NESTED: 'nested',
  EMAIL: 'email',
  PASSWORD: 'password',
  DECIMAL: 'decimal',
  PERCENTAGE: 'percentage',
  BOOLEAN: 'bool',
  RADIO: 'radio',
};

export const AttributeDataType = {
  DROPDOWN: 'dropdown',
  TEXT: 'text',
  TEXTAREA: 'area',
  BOOLEAN: 'bool',
};

export const attributeWidgetMap = {
  [AttributeDataType.DROPDOWN]: WidgetType.ASYNC_SELECT,
  [AttributeDataType.TEXT]: WidgetType.INPUT,
  [AttributeDataType.TEXTAREA]: WidgetType.TEXTAREA,
  [AttributeDataType.BOOLEAN]: WidgetType.SELECT,
};

export const booleanAttributeOptions = [
  {
    label: T('exist'),
    value: true,
  },
  {
    label: T('not.exist'),
    value: false,
  },
];

// reverse of the above (booleanAttributeOptions) for isNull queries
export const getIsNullBooleanAttributeOptions = ({ t }) => [
  {
    label: t('exist'),
    value: false,
  },
  {
    label: t('not.exist'),
    value: true,
  },
];

export const getIsPublicPrivateOptions = ({ t }) => [
  {
    label: t('messagesConfig.public'),
    value: true,
  },
  {
    label: t('messagesConfig.private'),
    value: false,
  },
];

export const limitQuery = {
  limit: 99999,
};

export const DEFAULT_QUERY_LIMIT = 9999;

export const OfferApprovalStatus = {
  REJECTED: 0,
  APPROVED: 1,
  PENDING_APPROVAL: 2,
  WAITING_FOR_SELLERS_APPROVAL: 3,
};

export const editableOfferApprovalStatuses = [
  OfferApprovalStatus.WAITING_FOR_SELLERS_APPROVAL,
  OfferApprovalStatus.REJECTED,
];

export const PackageItemStatus = {
  CANCELLED: 0,
  ACTIVE: 1,
};

export const PackageStatus = {
  CANCELED: 1,
  PREPARING: 2,
  READY: 3,
  SHIPPED: 4,
  DELIVERED: 5,
  FAILED: 6,
};

export const WidgetPropsKeys = {
  SHOW_SEARCH: 'showSearch',
};

export const FileExtension = {
  XLSX: 'xlsx',
  CSV: 'csv',
};

export const BulkImportState = {
  INITIAL: 0, // a client side status for ease of use
  PENDING: 1,
  PROCESSING: 2,
  SUCCEEDED: 3,
  P_SUCCEEDED: 4, // partially succeeded
  FAILED: 5,
};

export const BulkImportFinishedStates = [
  BulkImportState.SUCCEEDED,
  BulkImportState.P_SUCCEEDED,
  BulkImportState.FAILED,
];

export const antdProgressStatus = {
  active: 'active',
  exception: 'exception',
  normal: 'normal',
  success: 'success',
};

export const DatasourcePermissionLevel = {
  EMPLOYEE: 0,
  MANAGER: 1,
  ADMIN: 2,
};

export const getDatasourcePermissionLevelOptions = ({ t }) => [
  {
    display_name: t('employee'),
    value: DatasourcePermissionLevel.EMPLOYEE,
  },
  {
    display_name: t('manager'),
    value: DatasourcePermissionLevel.MANAGER,
  },
  {
    display_name: t('admin'),
    value: DatasourcePermissionLevel.ADMIN,
  },
];
