import '../style.scss';

import { getRequestFiltersParams } from '@common/index';
import { QueryParamsBuilder } from '@common/query-params-builder';
import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/AkinonNotification';
import AkinonTable from '@components/AkinonTable';
import { ExportFormat } from '@components/AkinonTable/components/StandardAkinonTableHeader/ExportModal';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { preOffersUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { FileExtension } from '@constants/commontypes';
import { defaultRowKey } from '@constants/index';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { useIsMobile } from '@hooks/useIsMobile';
import usePageData from '@hooks/usePageData';
import { getDynamicColumns } from '@pages/ProductsAndCategories/common';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { getExportPreOffers } from '@services/api';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { queryClient } from '@services/api/queryClient';
import { useResetTableCurrentPageWhenFiltersChanged } from '@utils/hooks';
import { useUserRole } from '@utils/hooks/useUserRole';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';
import { useRejectedOffersBreadcrumbs } from './hooks/useRejectedOffersBreadcrumbs';
import { useRejectedOffersPageMeta } from './hooks/useRejectedOffersPageMeta';

export default function OfferListRejected() {
  const { t } = useTranslation('ProductsAndCategories');
  const isMobile = useIsMobile();
  const navigate = useAppNavigate();
  const [pagination, setPagination] = usePagination();
  const { pageData } = usePageData();
  const visibleFilters = pageData?.visibleFilters ?? [];
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  useRejectedOffersBreadcrumbs();
  useRejectedOffersPageMeta();

  const { attributes, isAttributesLoading } = useAttributesQuery();

  const filterFields = useFilterFields({
    t,
    attributes,
    isAttributesLoading,
  });
  const filters = useFilterParams({
    filterFields,
  });

  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const { dynamicFormMetaFields } = getDynamicColumns({
    visibleFilters,
    dynamicFilterFields: filterFields.dynamic.fields,
  });

  const { data, isLoading, total } = useDataSource({
    pagination,
    filters,
    dynamicFormMetaFields,
  });
  const columns = getColumns({ t, isSuperUser, isMobile });

  const onExport = async ({ fieldsToExport, exportFormat }) => {
    if (exportFormat === ExportFormat.XLSX) {
      let requestConfig = {
        params: QueryParamsBuilder.new()
          .includesValues('approval_status', [0])
          .set('extension', FileExtension.XLSX)
          .getByFields(fieldsToExport)
          .build(),
      };

      await queryClient.fetchQuery({
        queryKey: [QueryKey.EXPORT_PRE_OFFERS, requestConfig, filters],
        queryFn: async () => {
          const fileFilter = Object.values(filters ?? {}).find((filter) => filter?.originFileObj);
          const filterParams = getRequestFiltersParams(filters);
          delete filterParams?.filter_file;
          requestConfig = {
            ...requestConfig,
            params: {
              ...requestConfig?.params,
              ...filterParams,
            },
          };

          const response = await getExportPreOffers({ requestConfig, fileFilter });
          return response;
        },
      });
      openNotification({
        message: t('file.preparing'),
        description: t('file.download.modal.description'),
      });
    }
  };

  const onRow = (offer) => ({
    onClick() {
      navigate(RouteUrls.productsAndCategories.offerList.detail, {
        offerId: offer.id,
      });
    },
  });

  return (
    <section className="offer-list">
      <AkinonFilter title={t('filter')} filterFields={filterFields} showFileImport total={total} />

      <div className="offer-list__table">
        <AkinonTable
          title={t('offer.list')}
          columns={columns}
          dataSource={data}
          optionsUrl={preOffersUrl}
          loading={isLoading}
          total={total}
          rowKey={defaultRowKey}
          exportFormats={[ExportFormat.XLSX]}
          onExport={onExport}
          pagination={pagination}
          setPagination={setPagination}
          description={`${total} ${t('results.found')}`}
          onRow={onRow}
          enableDynamicColumns
        />
      </div>
    </section>
  );
}
