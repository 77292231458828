import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonFormItem from '@components/AkinonFormItem';
import { openNotification } from '@components/AkinonNotification';
import AkinonSelect from '@components/AkinonSelect';
import { Form, Modal } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useOtherCargoCompanyOption } from '../../hooks';
import { CargoInfoModalFormKey, getChangeCargoCompanyParams } from '../common';

const ChangeCargoCompanyModal = ({
  onCancel,
  isVisible,
  setIsVisible,
  patchPackageMutate,
  shippingOptionList,
  activeShippingOptionList,
}) => {
  const { t } = useTranslation('Orders');
  const [cargoOtherOption] = useOtherCargoCompanyOption(shippingOptionList);
  const { control, reset, handleSubmit } = useForm();
  const activeShippingSelectOptions = createSelectOptions(activeShippingOptionList, {
    valueKey: 'id',
    labelKey: 'label',
  });
  const changeCargoCompany = (formValues) => {
    const params = getChangeCargoCompanyParams({
      formValues,
      shippingOptionList,
      cargoOtherOption,
    });

    patchPackageMutate(
      { params },
      {
        onSuccess() {
          openNotification({
            message: t('success'),
            description: t('change_cargo_company_success_message'),
          });
        },
        onError() {
          openNotification({
            type: 'error',
            message: t('error'),
            description: t('change_cargo_company_error_message'),
          });
        },
      }
    );
  };

  const onReset = () => {
    reset();
    setIsVisible(false);
  };

  return (
    <Modal
      destroyOnClose
      className="package-detail__action-form box-primary form-box"
      open={isVisible}
      onCancel={() => {
        onReset();
        onCancel();
      }}
      centered
      title={t('shipping_info')}
      footer={null}
    >
      <Form layout="vertical">
        <AkinonFormItem
          name={CargoInfoModalFormKey.CARGO_COMPANY}
          label={t('cargo_company')}
          control={control}
          required
        >
          <AkinonSelect options={activeShippingSelectOptions} showSearch />
        </AkinonFormItem>
        <Form.Item wrapperCol={{ span: 10, offset: 14 }}>
          <AkinonButton
            htmlType="submit"
            className="footer-button"
            type="primary"
            onClick={handleSubmit((formValues) => {
              onReset();
              changeCargoCompany(formValues);
            })}
          >
            {t('save').toUpperCase()}
          </AkinonButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangeCargoCompanyModal;
